import { useCallback } from 'react';
import { useApiContext } from '@omnipkg/ui-kit-web';
import { useQuery } from '@tanstack/react-query';

const ADAPTER_API = process.env.ADAPTER_API;
const ORG_UID = process.env.ORG_UID;

export interface Tariff {
  placement_cost: number;
  vat_cost: number;
  vat_rate: number;
  cost_without_vat: number;
}

function useGetTariffApi() {
  const { request } = useApiContext();

  return useCallback(async (): Promise<Tariff> => {
    const { data } = await request<Tariff>({
      baseURL: ADAPTER_API,
      url: `calculate/placement?organization_uid=${ORG_UID}`,
    });

    return data;
  }, [request]);
}

export function useGetTariff() {
  return useQuery({
    queryKey: ['tariff'],
    queryFn: useGetTariffApi(),
  });
}
