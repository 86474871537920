import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

import { CountryCode } from '../components/CountryPicker/types';

interface UserData {
  ip: string;
  city: string;
  region: string;
  country: CountryCode;
  loc: string;
}

interface HookArgs {
  isNeedToGetCountryByIp: boolean;
}

interface HookReturns {
  ipCountry?: CountryCode;
}

const API_IP_INFO = 'https://ipinfo.io';
const API_IP_INFO_TOKEN = '705ee21fd4bb20';

export function useGetCountryByIp({
  isNeedToGetCountryByIp,
}: HookArgs): HookReturns {
  const [ipCountry, setIpCountry] = useState<CountryCode>();

  const getCountryByIp = useCallback(async () => {
    try {
      const response = await axios.get<UserData>(
        `${API_IP_INFO}?token=${API_IP_INFO_TOKEN}`,
      );
      return response.data;
    } catch (err: AnyType) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (!isNeedToGetCountryByIp) return;

    getCountryByIp().then((data) => {
      if (data) setIpCountry(data.country);
    });
  }, [isNeedToGetCountryByIp, getCountryByIp]);

  return { ipCountry };
}
