import { LocationPoint } from '@omnic/widget-locations';
import { UserOmnic } from '@omnipkg/ui-kit-web';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export interface OrderData {
  customer_number: string;
  recipient_point: string;
  adapter_number: string;
  uid: string;
  status: number;
  cost: number;
  cube_core_ident?: string;
  placement_code?: string;
  recipient_code?: string;
  created_at: string;
}

export interface AuthData {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  tokenType: string;
}

type Store = {
  authData: AuthData | null;
  isRegistrationOpen: boolean;
  isMapOpen: boolean;
  client: UserOmnic | null;
  orderNumber: string;
  parcelLocker: LocationPoint | null;
  orderData: OrderData | null;
  setClient: (client: UserOmnic) => void;
  setOrderNumber: (value: string) => void;
  setParcelLocker: (parcelLocker: LocationPoint | null) => void;
  setIsMapOpen: (value: boolean) => void;
  setIsRegistrationOpen: (value: boolean) => void;
  setAuthData: (authData: AuthData) => void;
  setOrderData: (orderData: OrderData) => void;
};

export const useStore = create<Store>()(
  persist(
    (set) => ({
      authData: null,
      isRegistrationOpen: false,
      isMapOpen: false,
      client: null,
      orderNumber: '',
      parcelLocker: null,
      orderData: null,
      setClient: (client) => set({ client }),
      setOrderNumber: (value) => set({ orderNumber: value }),
      setParcelLocker: (parcelLocker) => set({ parcelLocker }),
      setIsMapOpen: (value) => set({ isMapOpen: value }),
      setIsRegistrationOpen: (value) => set({ isRegistrationOpen: value }),
      setAuthData: (authData) => set({ authData }),
      setOrderData: (orderData) => set({ orderData }),
    }),
    {
      name: 'store',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
