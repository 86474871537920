import { SVGIcon } from '@omnipkg/ui-kit-web';

import { FooterContainer } from './styled';

export default function Footer(): JSX.Element {
  return (
    <FooterContainer>
      <SVGIcon name="innovated-by-omnic" height={12} />
    </FooterContainer>
  );
}
