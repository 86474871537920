import { styled } from 'styled-components';

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
`;

export const MapContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
`;

export const MapWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
