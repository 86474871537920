import { ForwardedRef, RefObject, useImperativeHandle } from 'react';

import { InputRef } from '../../Input/types';
import { Country } from '../components/CountryPicker/types';
import { PhoneInputRef } from '../types';
import { useCheckIsError } from './useCheckIsError';

interface HookArgs {
  componentRef: ForwardedRef<PhoneInputRef>;
  phoneInputRef: RefObject<InputRef>;
  countryInputRef: RefObject<InputRef>;
  numberValue: string;
  countryData?: Country;
}

export function useImperativeBehavior({
  componentRef,
  phoneInputRef,
  countryInputRef,
  numberValue,
  countryData,
}: HookArgs) {
  const checkIsError = useCheckIsError(phoneInputRef, numberValue, countryData);

  useImperativeHandle(componentRef, () => {
    return {
      getPhoneInfo: () => {
        const isNumberCorrect = checkIsError();
        return {
          isNumberCorrect,
          phoneNumber: `${countryData?.dialCode}${numberValue}`,
          country: countryData,
        };
      },
      setNumber: (value: string) => {
        phoneInputRef.current?.setInput(value);
      },
      setCountry: (value: string) => {
        countryInputRef.current?.setInput(value);
      },
    };
  }, [countryData, numberValue, phoneInputRef, countryInputRef, checkIsError]);
}
