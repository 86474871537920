import { Outlet } from 'react-router-dom';

import { CONTENT_ID } from '@src/constants/common';

import HowItWorks from './components/HowItWorks';
import ProcessStatus from './components/ProcessStatus';

import { Content, MainContainer } from './styled';

export default function ProcessLayout(): JSX.Element {
  return (
    <MainContainer>
      <Content id={CONTENT_ID}>
        <HowItWorks />
        <Outlet />
      </Content>
      <ProcessStatus />
    </MainContainer>
  );
}
