import { ButtonsWrapper } from '@omnipkg/ui-kit-web';
import styled, { css } from 'styled-components';

import { H3, Txt1, Txt2, Txt2Bold } from '@src/theme/common';

import { CheckMarkContainerProps } from './types';

export const MainContainer = styled.aside`
  position: sticky;
  top: 0;
  flex-grow: 1;
  max-width: 440px;
  padding: ${({ theme }): string => theme.spacing(2.5)};
  border-radius: ${({ theme }): string => theme.shape.borderRadius.s};
  background-color: ${({ theme }): string => theme.palette.background.primary};
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }): string => theme.spacing(2.5)};

  @media (max-width: ${({ theme }): string => theme.breakpoints.sm}) {
    flex-grow: 0;
    position: relative;
    max-width: 100%;
    background-color: ${({ theme }): string =>
      theme.palette.background.secondary};
    border-radius: 0;
    border-top: 1px solid ${({ theme }): string => theme.palette.graphite[30]};
    padding-top: 0;
    gap: 0;
  }
`;

export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const StepContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing()};
  align-items: center;
`;

function applyCheckMarkStyles({ $stepStatus, theme }: CheckMarkContainerProps) {
  const lineColor =
    $stepStatus === 'future'
      ? theme.palette.grey[100]
      : theme.palette.primary.main;

  const backgroundImage = css`
    background-image: linear-gradient(
      to bottom,
      ${lineColor} 0%,
      ${lineColor} 20%,
      transparent 20%,
      transparent 35%,
      ${lineColor} 35%,
      ${lineColor} 65%,
      transparent 65%,
      transparent 80%,
      ${lineColor} 80%,
      ${lineColor} 100%
    );
  `;

  switch ($stepStatus) {
    case 'completed':
      return css`
        background-color: ${theme.palette.primary.main};
        border-color: ${theme.palette.primary.main};

        &:before {
          ${backgroundImage};
        }
      `;
    case 'active':
      return css`
        border-color: ${theme.palette.primary.main};

        &:before {
          ${backgroundImage};
        }
      `;
    default:
      return css`
        border-color: ${theme.palette.grey[100]};

        &:before {
          ${backgroundImage};
        }
      `;
  }
}

export const CheckMarkContainer = styled.div<CheckMarkContainerProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  ${applyCheckMarkStyles};

  &[data-first='true']:before {
    background-image: none;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: calc(100% + 4px);
    width: 2px;
    height: calc(100% - 4px);
  }
`;

export const StepName = styled.span`
  ${Txt1};
`;

export const TotalCostContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing()};
  padding: ${({ theme }) => theme.spacing()} 0;
  border-top: 1px solid ${({ theme }) => theme.palette.grey[10]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[10]};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    border-top: none;
    border-bottom: none;
  }
`;

export const CostAndVat = styled.div`
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;

    &[data-active='true'] {
      display: block;
    }
  }
`;

export const CostsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};
`;

export const CostDataContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing()};
  justify-content: space-between;
  align-items: center;
  height: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: calc(100% - 28px); // 20px for svg + 8px for gap
  }
`;

export const SVGPaper = styled.div``;

export const TotalCostDataContainer = styled.button`
  display: flex;
  gap: ${({ theme }) => theme.spacing()};
  justify-content: space-between;
  align-items: center;
  height: 22px;

  background: none;
  border: none;
  outline: none;
  pointer-events: none;
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;

  ${SVGPaper} {
    display: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    pointer-events: all;
    cursor: pointer;

    ${SVGPaper} {
      display: block;
      transition-duration: ${({ theme }) => theme.transitionDuration.normal};
    }

    &[data-active='true'] {
      ${SVGPaper} {
        transform: rotate(180deg);
      }
    }
  }
`;

export const SVGContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const TotalCostWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing()};
`;

export const TotalCostLabel = styled.span`
  ${Txt1};
  color: ${({ theme }) => theme.palette.primary.main};
  display: inline-flex;
  align-items: center;
`;

export const TotalCostValue = styled.div`
  ${H3};
  gap: ${({ theme }) => theme.spacing(0.5)};
  display: inline-flex;
  align-items: center;
  direction: rtl;

  * {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const CostLabel = styled(TotalCostLabel)`
  ${Txt2};
`;

export const CostValue = styled(TotalCostValue)`
  ${Txt2Bold};
`;

export const ButtonsWrapperStyled = styled(ButtonsWrapper)`
  height: fit-content;
  margin-top: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    button[data-back='true'] {
      display: none;
    }
  }
`;
