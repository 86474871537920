import {
  Dispatch,
  Ref,
  RefObject,
  SetStateAction,
  useImperativeHandle,
  useRef,
} from 'react';
import { useTranslations } from '@omnipkg/ui-kit-web';

import { InputError, InputRef } from '../types';

interface HookArgs {
  ref?: Ref<InputRef>;
  required: boolean;
  minPasswordLength: number;
  value?: string;
  setError: Dispatch<SetStateAction<InputError>>;
}

export function useInputRef({
  ref,
  required,
  minPasswordLength,
  value = '',
  setError,
}: HookArgs): RefObject<HTMLInputElement> {
  const innerRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslations();

  useImperativeHandle(ref, () => {
    return {
      setInput: (valueToSet: string) => {
        const input = innerRef.current;
        if (!input) return;

        const valueSetter = Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          'value',
        )?.set;
        valueSetter?.call(input, valueToSet);

        input.style.caretColor = 'transparent';
        const event = new Event('input', { bubbles: true });
        input.dispatchEvent(event);
        input.focus();
      },
      setInputError: (errorText: string) => {
        setError({
          isError: true,
          errorMessage: errorText,
        });
      },
      isEmpty: () => {
        const isEmpty = !value.trim();
        if (required && isEmpty) {
          setError({
            isError: true,
            errorMessage: t('cannot_be_empty'),
          });
        }
        return required && isEmpty;
      },
      isPasswordValid: () => {
        const isPasswordValid = value.length >= minPasswordLength;

        if (!isPasswordValid) {
          setError({
            isError: true,
            errorMessage: `${t(
              'password_requirement1',
            )} ${minPasswordLength} ${t('password_requirement2')}`,
          });
        }
        return isPasswordValid;
      },
      isEmailValid: () => {
        const isEmpty = !value.trim();
        const isEmailValid = /\S+@\S+\.\S+/.test(value);

        if (isEmpty) {
          if (required) {
            setError({
              isError: true,
              errorMessage: t('cannot_be_empty'),
            });
          }

          return !required;
        }

        if (!isEmailValid && value.trim()) {
          setError({
            isError: true,
            errorMessage: t('email_is_not_valid'),
          });
        }
        return isEmailValid;
      },
    };
  }, [required, value, minPasswordLength, t, setError]);

  return innerRef;
}
