import styled, { css } from 'styled-components';

export const MOBILE_HEADER_HEIGHT = 56;
export const MOBILE_FOOTER_HEIGHT = 20;

export const headerHeight = css`
  height: 64px;
  min-height: 64px;

  @media (max-width: ${({ theme }): string => theme.breakpoints.sm}) {
    height: ${MOBILE_HEADER_HEIGHT}px;
    min-height: ${MOBILE_HEADER_HEIGHT}px;
  }
`;

export const H2 = css`
  font-size: ${({ theme }): string => theme.typography.fontSize(24)};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[700]};
`;

export const H3 = css`
  font-size: ${({ theme }): string => theme.typography.fontSize(20)};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[600]};
`;

export const Txt1 = css`
  font-size: ${({ theme }): string => theme.typography.fontSize(16)};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
`;

export const Txt1Bold = css`
  ${Txt1};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[700]};
`;

export const Txt1Upper = css`
  ${Txt1};
  text-transform: uppercase;
`;

export const Txt2 = css`
  font-size: ${({ theme }): string => theme.typography.fontSize(14)};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
`;

export const Txt2Bold = css`
  ${Txt2}
  font-weight: ${({ theme }): string => theme.typography.fontWeight[700]};
`;

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }): string => theme.spacing(4.5)};
  padding: ${({ theme }): string => theme.spacing(2.5)};
  background-color: ${({ theme }): string => theme.palette.background.primary};
  border-radius: ${({ theme }): string => theme.shape.borderRadius.s};
`;

export const Title = styled.h2`
  ${Txt1Bold};
`;
