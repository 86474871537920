import { useRef } from 'react';
import { SVGIcon, useTranslations } from '@omnipkg/ui-kit-web';

import SideTab from '@src/components/SideTab';
import { SideTabRef } from '@src/components/SideTab/types';

import HowItWorksTab from './components/HowItWorksTab';

import {
  HowItWorksContainer,
  HowItWorksTitle,
  HowItWorksTitleWrapper,
} from './styled';

export default function HowItWorks(): JSX.Element {
  const sideTabRef = useRef<SideTabRef>(null);

  const { t } = useTranslations();

  function openHowItWorksTab() {
    sideTabRef.current?.open();
  }

  function closeHowItWorksTab() {
    sideTabRef.current?.close();
  }

  return (
    <>
      <HowItWorksContainer
        role="button"
        aria-label="open how-it-works hints"
        tabIndex={0}
        onClick={openHowItWorksTab}
      >
        <HowItWorksTitleWrapper>
          <SVGIcon name="info" size={16} />
          <HowItWorksTitle>{t('how_it_works')}</HowItWorksTitle>
        </HowItWorksTitleWrapper>
        <SVGIcon name="chevron" size={16} rotate={-90} />
      </HowItWorksContainer>
      <SideTab ref={sideTabRef}>
        <HowItWorksTab closeTab={closeHowItWorksTab} />
      </SideTab>
    </>
  );
}
