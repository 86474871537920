import styled from 'styled-components';

import { MainContainer, Txt1, Txt1Bold, Txt1Upper } from '@src/theme/common';

export const CustomMainContainer = styled(MainContainer)`
  gap: ${({ theme }): string => theme.spacing(2)};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing()};
`;

export const Title = styled.span`
  ${Txt1Upper};
  font-weight: 700;
`;

export const Description = styled.p`
  font-size: ${({ theme }): string => theme.typography.fontSize(18)};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
`;

export const VisualContainer = styled.div`
  height: 84px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing(1.5)};
  border-radius: ${({ theme }): string => theme.shape.borderRadius.s};
  background-color: ${({ theme }): string => theme.palette.primary[5]};
`;

export const VisualUnit = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing()};

  @media (max-width: ${({ theme }): string => theme.breakpoints.sm}) {
    flex-direction: column;
    gap: ${({ theme }): string => theme.spacing(0.5)};

    img {
      scale: 0.8;
    }
  }
`;

export const VisualUnitTitle = styled.span`
  ${Txt1Bold};
  color: ${({ theme }): string => theme.palette.primary.main};

  @media (max-width: ${({ theme }): string => theme.breakpoints.sm}) {
    font-size: ${({ theme }): string => theme.typography.fontSize(12)};
  }
`;

export const VisualDivider = styled.div`
  width: 1px;
  height: 40px;
  background-color: ${({ theme }): string => theme.palette.primary.main};
`;

export const SideTabButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: transparent;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
`;

export const ButtonTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing(0.5)};
`;

export const ButtonTitle = styled.span`
  ${Txt1};
`;
