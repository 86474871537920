import { useCallback, useEffect, useMemo, useState } from 'react';
import { formattingString, useTranslations } from '@omnipkg/ui-kit-web';

import { useGetCountryByIp } from '../../hooks/useGetCountryByIp';
import { countries } from './countries';
import { Country, CountryFullData, CountryPickerProps } from './types';

import {
  CountryDialCode,
  CountryFlag,
  CountryInfo,
  CountryList,
  CountryListItem,
  CountryName,
  CountryPickerContainer,
} from './styled';

export default function CountryPicker({
  foundCountry,
  initialCountry,
  isShownPicker,
  countryValue,
  showSearching,
  pickerPosition = 'bottom',
  input,
  onCountrySelect: sendCountryData,
  setIsShownPicker,
}: CountryPickerProps): JSX.Element {
  const [wasManualSelection, setWasManualSelection] = useState(false);

  const { langData } = useTranslations();
  const { ipCountry } = useGetCountryByIp({
    isNeedToGetCountryByIp: !initialCountry,
  });

  const filteredList = useMemo(
    () =>
      !Number.isNaN(Number(countryValue))
        ? countries.filter((country) => country.dialCode.includes(countryValue))
        : countries.filter((country) =>
            country.name[langData.lang]
              .toLowerCase()
              .includes(
                formattingString(countryValue.toLowerCase(), 'withSpaces'),
              ),
          ),
    [langData, countryValue],
  );

  const listToRender = filteredList.length ? filteredList : countries;

  const onCountrySelect = (country: Country) => () => {
    setWasManualSelection(true);
    sendCountryData(country, true);
    setIsShownPicker(false);
  };

  const defaultCountry = useMemo(() => {
    const countyToPick = ipCountry || initialCountry || 'US';

    return countries.find(
      ({ code }) => code === countyToPick,
    ) as CountryFullData;
  }, [ipCountry, initialCountry]);

  const sendCountryDataHandler = useCallback(
    (country: CountryFullData) => {
      sendCountryData({
        ...country,
        name: country.name[langData.lang],
      });
    },
    [langData, sendCountryData],
  );

  useEffect(() => {
    if (wasManualSelection) return;

    if (foundCountry) {
      sendCountryDataHandler(foundCountry);
      return;
    }

    sendCountryDataHandler(defaultCountry);
  }, [
    foundCountry,
    defaultCountry,
    wasManualSelection,
    sendCountryDataHandler,
  ]);

  return (
    <CountryPickerContainer
      $isShownPicker={isShownPicker}
      $pickerPosition={pickerPosition}
    >
      {showSearching && input}
      <CountryList>
        {listToRender.map((country) => {
          const name = country.name[langData.lang];
          const { flag, dialCode } = country;
          return (
            <CountryListItem
              key={country.code}
              onClick={onCountrySelect({ ...country, name })}
            >
              <CountryFlag>{flag}</CountryFlag>
              <CountryInfo>
                <CountryDialCode>{dialCode}</CountryDialCode>
                <CountryName>{name}</CountryName>
              </CountryInfo>
            </CountryListItem>
          );
        })}
      </CountryList>
    </CountryPickerContainer>
  );
}
