import { useCallback } from 'react';
import { useApiContext } from '@omnipkg/ui-kit-web';
import { useMutation } from '@tanstack/react-query';

import { OrderData } from '@src/store/store';

const ADAPTER_API = process.env.ADAPTER_API;

interface Args {
  recipientPoint: string;
  orderNumber: string;
  token: string;
  tokenType: string;
}

interface Request {
  recipient_point: string;
  order_number: string;
}

function useCreateOrderApi() {
  const { request } = useApiContext();

  return useCallback(
    async (args: Args): Promise<OrderData> => {
      const { data } = await request<OrderData, Request>({
        method: 'POST',
        baseURL: ADAPTER_API,
        url: '/orders',
        data: {
          recipient_point: args.recipientPoint,
          order_number: args.orderNumber,
        },
        headers: {
          Authorization: `${args.tokenType} ${args.token}`,
        },
      });

      return data;
    },
    [request],
  );
}

export function useCreateOrder() {
  const createOrder = useCreateOrderApi();

  return useMutation<OrderData, unknown, Args>({
    mutationFn: createOrder,
  });
}
