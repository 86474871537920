import {
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributes,
  useRef,
  useState,
} from 'react';
import { useTranslations } from '@omnipkg/ui-kit-web';

import Input from '../Input';
import { InputRef } from '../Input/types';
import CountryPicker from './components/CountryPicker/CountryPicker';
import { Country, CountryPickerProps } from './components/CountryPicker/types';
import { useImperativeBehavior } from './hooks/useImperativeBehavior';
import { useNumberProcessing } from './hooks/useNumberProcessing';
import { usePickerProcessing } from './hooks/usePickerProcessing';
import { PhoneInputProps, PhoneInputRef } from './types';

import { InputWrapper, PhoneInputContainer } from './styled';

const PhoneInput = forwardRef<PhoneInputRef, PhoneInputProps>(
  (
    {
      label,
      initialNumber,
      initialCountry,
      searchingInputName,
      pickerPosition,
      countryPickerMode = 'available',
      excludedELements,
      onChange: sendPhoneData,
      onSearchingInputClick,
      onSearchingInputChange,
      onPickerChangeState,
      onClick,
      ...inputProps
    },
    ref,
  ): JSX.Element => {
    const [numberValue, setNumberValue] = useState('');
    const [countryData, setCountryData] = useState<Country>();

    const { t } = useTranslations();

    const phoneInputRef = useRef<InputRef>(null);
    const countryInputRef = useRef<InputRef>(null);

    const { foundCountry, changeNumberHandler } = useNumberProcessing({
      countryData,
      initialNumber,
      setNumberValue,
      sendPhoneData,
    });

    const {
      countryValue,
      isShownPicker,
      countryPickerRef,
      selectCountryHandler,
      setPickerVisibility,
      setCountryValueHandler,
      setIsShownPicker,
    } = usePickerProcessing({
      excludedELements,
      setNumberValue,
      setCountryData,
      sendPhoneData,
      onSearchingInputChange,
      onPickerChangeState,
    });

    useImperativeBehavior({
      componentRef: ref,
      phoneInputRef,
      countryInputRef,
      numberValue,
      countryData,
    });

    const countryPickerProps: CountryPickerProps = {
      isShownPicker,
      initialCountry,
      countryValue,
      pickerPosition,
      foundCountry,
      setIsShownPicker,
      onCountrySelect: selectCountryHandler,
      input: (
        <Input
          ref={countryInputRef}
          value={countryValue}
          onChange={({ target: { value } }) => {
            setCountryValueHandler(value);
            onSearchingInputChange?.(value);
          }}
          name={searchingInputName}
          freeWidth={true}
          onClick={onSearchingInputClick}
        />
      ),
    };

    const inputWrapperProps: DetailedHTMLProps<
      HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    > = {
      ref: countryPickerRef,
    };

    return (
      <PhoneInputContainer $freeWidth={inputProps.freeWidth}>
        {countryPickerMode === 'available' && (
          <InputWrapper {...inputWrapperProps}>
            <Input
              ref={countryInputRef}
              name={searchingInputName}
              label={t('country')}
              onClick={(event) => {
                setPickerVisibility();
                onSearchingInputClick?.(event);
              }}
              value={isShownPicker ? countryValue : countryData?.name || ''}
              onChange={({ target: { value } }) => {
                setCountryValueHandler(value);
                onSearchingInputChange?.(value);
              }}
              required
              {...inputProps}
            />
            <CountryPicker {...countryPickerProps} />
          </InputWrapper>
        )}

        <InputWrapper {...inputWrapperProps}>
          <Input
            ref={phoneInputRef}
            value={numberValue}
            onChange={({ target: { value } }) => changeNumberHandler(value)}
            onClick={(event) => {
              setIsShownPicker(false);
              onClick?.(event);
            }}
            phoneManager={
              countryData && {
                phoneCode: countryData.dialCode,
                isOpenPicker: isShownPicker,
                onPhoneCodeClick:
                  countryPickerMode === 'onlyNumber'
                    ? setPickerVisibility
                    : undefined,
              }
            }
            label={label}
            type="tel"
            required
            {...inputProps}
          />
          {countryPickerMode === 'onlyNumber' && (
            <CountryPicker showSearching {...countryPickerProps} />
          )}
        </InputWrapper>
      </PhoneInputContainer>
    );
  },
);

PhoneInput.displayName = 'PhoneInput';
export default PhoneInput;
