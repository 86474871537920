import styled from 'styled-components';

import { headerHeight } from '@src/theme/common';

export const MainContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 3;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    background-color: transparent;
  }
`;

export const SideTabContainer = styled.div`
  width: 468px;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.background.primary};
  display: flex;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
  }
`;

export const SideTabHeader = styled.div`
  ${headerHeight};
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing(2)};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.graphite[30]};
`;

export const SideTabContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex: 1;
  padding: ${({ theme: { spacing } }) => `${spacing(7.5)}`} 0;
  overflow-y: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: ${({ theme: { spacing } }) => `${spacing(5)}`} 0;
  }
`;

export const CloseButton = styled.button`
  cursor: pointer;
  display: block;
  outline: none;
  border: none;
  background-color: transparent;
`;
