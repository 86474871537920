import { useCallback, useState } from 'react';
import {
  useErrorHandlerContext,
  useNotifications,
  useQueryNavigate,
  useTranslations,
} from '@omnipkg/ui-kit-web';

import { useStore } from '@src/store/store';
import { Path } from '@src/types/routes';

import { useCheckCode } from '../utils/checkCode';
import { useGetUserInfo } from '../utils/getUserInfo';

interface HookReturns {
  isCodeChecking: boolean;
  checkCode: (code: string) => void;
}

export function useCheckCodeHandler(phone: string): HookReturns {
  const [isPending, setIsPending] = useState(false);

  const { setAuthData, setClient, setIsRegistrationOpen } = useStore();
  const navigate = useQueryNavigate();

  const { handleError } = useErrorHandlerContext();

  const { t } = useTranslations();
  const { setNotification, closeNotification } = useNotifications();

  const checkCodeRequest = useCheckCode();
  const getUserInfo = useGetUserInfo();

  const checkCode = useCallback(
    (code: string) => {
      setIsPending(true);
      checkCodeRequest({ code, phone })
        .then((data) => {
          if (!data) {
            throw new Error('No data received');
          }
          closeNotification();
          setAuthData({
            accessToken: data.access_token,
            refreshToken: data.refresh_token,
            expiresIn: data.expires_in,
            tokenType: data.token_type,
          });

          return {
            token: data.access_token,
            tokenType: data.token_type,
          };
        })
        .then(({ token, tokenType }) => getUserInfo({ token, tokenType }))
        .then((userInfo) => {
          if (!userInfo) {
            throw new Error('No userInfo received');
          }
          setClient(userInfo);
          setIsRegistrationOpen(false);
          navigate(Path.clientData);
        })
        .catch((error) => {
          if (
            error.stack?.error_description ===
            'Invalid "phone" or "code" in request.'
          ) {
            setNotification({
              value: t('invalid_phone_or_code'),
              status: 'error',
            });
            return;
          }
          handleError(error.message);
        })
        .finally(() => setIsPending(false));
    },
    [
      phone,
      t,
      handleError,
      setAuthData,
      setClient,
      setNotification,
      closeNotification,
      getUserInfo,
      checkCodeRequest,
      navigate,
      setIsRegistrationOpen,
    ],
  );

  return { isCodeChecking: isPending, checkCode };
}
