import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
  useTransition,
} from 'react';
import { useOutsideClick } from '@omnipkg/ui-kit-web';

import { Country } from '../components/CountryPicker/types';
import { PhoneInputProps } from '../types';

interface HookArgs {
  excludedELements: PhoneInputProps['excludedELements'];
  setCountryData: Dispatch<SetStateAction<Country | undefined>>;
  setNumberValue: Dispatch<SetStateAction<string>>;
  sendPhoneData: PhoneInputProps['onChange'];
  onSearchingInputChange: PhoneInputProps['onSearchingInputChange'];
  onPickerChangeState: PhoneInputProps['onPickerChangeState'];
}

interface HookReturns {
  countryValue: string;
  isShownPicker: boolean;
  countryPickerRef: RefObject<HTMLDivElement>;
  selectCountryHandler: (country: Country, isManualChange?: boolean) => void;
  setCountryValueHandler: (value: string) => void;
  setPickerVisibility: () => void;
  setIsShownPicker: Dispatch<SetStateAction<boolean>>;
}

export function usePickerProcessing({
  excludedELements,
  setCountryData,
  setNumberValue,
  sendPhoneData,
  onSearchingInputChange,
  onPickerChangeState,
}: HookArgs): HookReturns {
  const [countryValue, setCountryValue] = useState('');
  const [isShownPicker, setIsShownPicker] = useState(false);
  const [, startTransition] = useTransition();

  const countryPickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    onPickerChangeState?.(isShownPicker);
  }, [isShownPicker, onPickerChangeState]);

  const selectCountryHandler = useCallback(
    (country: Country, isManualChange?: boolean) => {
      setCountryData(country);
      if (isManualChange) {
        setCountryValue(country.name);
        setNumberValue('');
        sendPhoneData?.({
          country,
          number: '',
          fullNumber: '',
        });
      }
    },
    [sendPhoneData, setCountryData, setNumberValue],
  );

  function setPickerVisibility() {
    setIsShownPicker((prevState) => !prevState);
    setCountryValue('');
    onSearchingInputChange?.('');
  }

  const setCountryValueHandler = useCallback(
    (value: string) => {
      startTransition(() => {
        setCountryValue(value);
      });
      onSearchingInputChange?.(value);
    },
    [onSearchingInputChange],
  );

  useOutsideClick({
    ref: countryPickerRef,
    isOpen: isShownPicker,
    exclude: excludedELements,
    onOutsideClick: () => setIsShownPicker(false),
  });

  return {
    countryValue,
    isShownPicker,
    countryPickerRef,
    selectCountryHandler,
    setPickerVisibility,
    setCountryValueHandler,
    setIsShownPicker,
  };
}
