import { InputRef } from '@omnipkg/ui-kit-web';

import { Tariff } from '@src/api/getTariff';
import { SideTabRef } from '@src/components/SideTab/types';

export interface Props {
  children: React.ReactNode;
}

export enum Field {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
}

export type UserFields = {
  [K in Field]: string;
};

export interface ConfirmActions {
  apply: () => void;
  cancel: () => void;
}

export interface AppContextValue {
  firstNameRef: React.RefObject<InputRef>;
  lastNameRef: React.RefObject<InputRef>;
  emailRef: React.RefObject<InputRef>;
  orderNumberRef: React.RefObject<InputRef>;
  parcelLockerRef: React.RefObject<InputRef>;
  tariffData?: Tariff;
  isTariffLoading: boolean;
  fields: UserFields;
  isCreating: boolean;
  prohibitedGoodsRef: React.RefObject<SideTabRef>;
  confirmActions?: ConfirmActions;
  setIsRegistrationAvailable: React.Dispatch<React.SetStateAction<boolean>>;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  authUser: () => void;
  getPlacementCode: () => void;
}
