import { useCallback } from 'react';
import { useApiContext } from '@omnipkg/ui-kit-web';
import { useQuery } from '@tanstack/react-query';

import { ParcelLockerResponse } from './types/parcelLocker';

const ECOM_API = process.env.ECOM_API;
const INN = process.env.INN;

function useGetParcelLockersApi() {
  const { request } = useApiContext();

  return useCallback(
    async (value: string): Promise<ParcelLockerResponse> => {
      const { data } = await request<ParcelLockerResponse>({
        baseURL: ECOM_API,
        url: `type-1/public/locations/points?status=30&page_size=700&q=${value}`,
        headers: {
          Authorization: `INN ${INN}`,
        },
      });

      return data;
    },
    [request],
  );
}

export function useGetParcelLockers(value: string) {
  const getParcelLockers = useGetParcelLockersApi();

  return useQuery({
    queryKey: ['tariff', value],
    queryFn: () => getParcelLockers(value),
    refetchOnMount: false,
    enabled: false,
  });
}
