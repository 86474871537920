import { useEffect } from 'react';
import {
  Button,
  SVGIcon,
  useQueryNavigate,
  useTranslations,
} from '@omnipkg/ui-kit-web';

import { useBackToSiteButton } from '@src/hooks/useBackToSiteButton';
import { useStore } from '@src/store/store';
import { Path } from '@src/types/routes';

import {
  ButtonWrapper,
  Code,
  CodeContainer,
  Copy,
  MainContainer,
  Message,
  Title,
} from './styles';

export default function PlacementCode(): JSX.Element {
  const { orderData } = useStore();

  const { t } = useTranslations();
  const navigate = useQueryNavigate();

  const placementCode = orderData?.placement_code || '0';

  const { backToSite } = useBackToSiteButton();

  useEffect(() => {
    if (!orderData) {
      navigate(Path.main);
    }
  }, [orderData, navigate]);

  function copyToClipboard(): void {
    navigator.clipboard.writeText(placementCode);
  }
  return (
    <MainContainer>
      <CodeContainer>
        <Title>{t('placement_code')}</Title>
        <Code>
          {placementCode}
          <Copy
            aria-label="copy placement code"
            type="button"
            tabIndex={0}
            onClick={copyToClipboard}
          >
            <SVGIcon name="copy" />
          </Copy>
        </Code>
      </CodeContainer>
      <Message>{t('use_this_code_to_place_this_order')}</Message>
      <ButtonWrapper>
        <Button
          label={t('back_to_site')}
          variant="contained"
          onClick={backToSite}
        />
      </ButtonWrapper>
    </MainContainer>
  );
}
