import { useEffect, useRef, useState } from 'react';
import { LocationPoint } from '@omnic/widget-locations';
import { Loader, useOutsideClick, useTranslations } from '@omnipkg/ui-kit-web';

import { useStore } from '@src/store/store';

import { Props } from './types';

import {
  NoData,
  ParcelLockerAddress,
  ParcelLockerName,
  SearcherContainer,
  SearcherItem,
  SearcherList,
} from './styled';

export default function Searcher({
  inputValue,
  isLoading,
  parcelLockers,
}: Props): JSX.Element | null {
  const [isOpenSelect, setIsOpenList] = useState(false);
  const { setParcelLocker } = useStore();

  const { t } = useTranslations();

  const searcherRef = useRef<HTMLDivElement>(null);

  useOutsideClick({
    isOpen: isOpenSelect,
    ref: searcherRef,
    onOutsideClick: () => setIsOpenList(false),
  });

  useEffect(() => {
    if (inputValue.length > 2) {
      setIsOpenList(true);
    } else {
      setIsOpenList(false);
    }
  }, [inputValue, isLoading]);

  function onItemClick(item: LocationPoint) {
    setIsOpenList(false);
    setParcelLocker(item);
  }

  function renderSelectionComponent() {
    if (isLoading) {
      return <Loader size={'40'} />;
    }

    if (Array.isArray(parcelLockers) && !parcelLockers.length) {
      return <NoData>{t('no_data')}</NoData>;
    }

    if (parcelLockers?.length) {
      return (
        <SearcherList>
          {parcelLockers?.map((item) => {
            const randomKey = Math.random().toString(36).substring(7);

            return (
              <SearcherItem
                key={item.name + randomKey}
                onClick={() => onItemClick(item)}
              >
                <ParcelLockerName>{item.name}</ParcelLockerName>
                <ParcelLockerAddress>
                  {item.location.full_address}
                </ParcelLockerAddress>
              </SearcherItem>
            );
          })}
        </SearcherList>
      );
    }

    // Case when data is not loaded yet and user is typing
    if (inputValue) {
      return <Loader size={'40'} />;
    }
  }

  const component = renderSelectionComponent();

  return isOpenSelect ? (
    <SearcherContainer ref={searcherRef}>{component}</SearcherContainer>
  ) : null;
}
