import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { SVGIcon, useOutsideClick } from '@omnipkg/ui-kit-web';

import { Props, SideTabRef } from './types';

import {
  CloseButton,
  MainContainer,
  SideTabContainer,
  SideTabContent,
  SideTabHeader,
} from './styled';

const SideTab = forwardRef<SideTabRef, Props>(
  ({ children, storageKey, onClose }, ref): JSX.Element | null => {
    const [isOpenSideTab, setIsOpenSideTab] = useState(false);

    const sideTabRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (storageKey) {
        const isSideTabOpen = sessionStorage.getItem(storageKey); // it can be 'true' or null
        if (isSideTabOpen) {
          setIsOpenSideTab(true);
        }
      }
    }, [storageKey]);

    function openSideTab() {
      setIsOpenSideTab(true);
      if (storageKey) {
        sessionStorage.setItem(storageKey, 'true');
      }
    }

    function closeSideTab() {
      setIsOpenSideTab(false);
      if (storageKey) {
        sessionStorage.removeItem(storageKey);
      }
      onClose?.();
    }

    useOutsideClick({
      isOpen: isOpenSideTab,
      ref: sideTabRef,
      onOutsideClick: closeSideTab,
    });

    useImperativeHandle(ref, () => ({
      open: openSideTab,
      close: closeSideTab,
    }));

    return isOpenSideTab ? (
      <MainContainer>
        <SideTabContainer ref={sideTabRef}>
          <SideTabHeader>
            <CloseButton
              aria-label="close"
              type="button"
              tabIndex={0}
              onClick={closeSideTab}
            >
              <SVGIcon name="close" size={20} />
            </CloseButton>
          </SideTabHeader>
          <SideTabContent>{children}</SideTabContent>
        </SideTabContainer>
      </MainContainer>
    ) : null;
  },
);

SideTab.displayName = 'SideTab';
export default SideTab;
