import styled from 'styled-components';

import { H2, Txt1 } from '@src/theme/common';

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${({ theme }): string => theme.spacing(4)};
  gap: ${({ theme }): string => theme.spacing(5)};
  overflow-y: auto;
  flex-shrink: 1;

  @media (max-width: ${({ theme }): string => theme.breakpoints.sm}) {
    padding: 0 ${({ theme }): string => theme.spacing(2)};
    gap: ${({ theme }): string => theme.spacing(3)};
  }
`;

export const Title = styled.h1`
  ${H2};
  text-align: center;

  @media (max-width: ${({ theme }): string => theme.breakpoints.sm}) {
    font-size: ${({ theme }): string => theme.typography.fontSize(18)};
    font-weight: 400;
  }
`;

export const GoodsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: ${({ theme }): string => theme.spacing(3)};

  @media (max-width: ${({ theme }): string => theme.breakpoints.sm}) {
    grid-template-columns: repeat(2, auto);
    grid-column-gap: ${({ theme }): string => theme.spacing(7)};
    grid-row-gap: ${({ theme }): string => theme.spacing(2)};
  }
`;

export const ProhibitedItemContainer = styled.div`
  max-width: 114px;
`;

export const ProhibitedItemSVGWrapper = styled.div`
  padding: ${({ theme }): string => theme.spacing(2)};
`;

export const ProhibitedItemName = styled.p`
  ${Txt1};
  width: 100%;
  max-width: inherit;
  text-align: center;

  @media (max-width: ${({ theme }): string => theme.breakpoints.sm}) {
    font-size: ${({ theme }): string => theme.typography.fontSize(12)};
  }
`;
