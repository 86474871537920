import { useEffect, useRef, useState } from 'react';

import SideTab from '@src/components/SideTab';
import { SideTabRef } from '@src/components/SideTab/types';
import { useStore } from '@src/store/store';

import Code from './components/Code';
import PhoneNumber from './components/PhoneNumber';
import useGetConfirmCodeHandler from './hooks/useGetConfirmCodeHandler';
import { AuthStep } from './types';

import { Paper } from './styled';

export default function Registration(): JSX.Element | null {
  const [phone, setPhone] = useState('');
  const [authStep, setAuthStep] = useState<AuthStep>('phone');

  const sideTabRef = useRef<SideTabRef>(null);
  const { isRegistrationOpen, setIsRegistrationOpen } = useStore();

  useEffect(() => {
    if (isRegistrationOpen) {
      sideTabRef.current?.open();
    } else {
      sideTabRef.current?.close();
    }
  }, [isRegistrationOpen]);

  const getCodeData = useGetConfirmCodeHandler();

  return (
    <SideTab ref={sideTabRef} onClose={() => setIsRegistrationOpen(false)}>
      <Paper>
        {authStep === 'phone' ? (
          <PhoneNumber
            {...getCodeData}
            setAuthStep={setAuthStep}
            setPhone={setPhone}
          />
        ) : (
          <Code phone={phone} setAuthStep={setAuthStep} {...getCodeData} />
        )}
      </Paper>
    </SideTab>
  );
}
