import { useState } from 'react';
import { useMatch } from 'react-router-dom';
import {
  Button,
  Loader,
  SVGIcon,
  useQueryNavigate,
  useTranslations,
} from '@omnipkg/ui-kit-web';
import { useTheme } from 'styled-components';

import { useAppContext } from '@src/contexts/AppContext/AppContext';
import { useStepsHandler } from '@src/hooks/useStepsHandler';
import { useStore } from '@src/store/store';
import { Path } from '@src/types/routes';
import { scrollTo } from '@src/utils/scrollToTop';

import Receipt from './svg/receipt';

import {
  ButtonsWrapperStyled,
  CheckMarkContainer,
  CostAndVat,
  CostDataContainer,
  CostLabel,
  CostsWrapper,
  CostValue,
  MainContainer,
  StepContainer,
  StepName,
  StepsWrapper,
  SVGContainer,
  SVGPaper,
  TotalCostContainer,
  TotalCostDataContainer,
  TotalCostLabel,
  TotalCostValue,
  TotalCostWrapper,
} from './styled';

export default function ProcessStatus(): JSX.Element {
  const [isExpandedCost, setIsExpandedCost] = useState(false);

  const { stepsData, getNextButtonData } = useStepsHandler();
  const { label, isLoading, nextBtnAction } = getNextButtonData();
  const { isTariffLoading, tariffData } = useAppContext();
  const { orderNumber, parcelLocker } = useStore();
  const { palette } = useTheme();

  const totalCost = tariffData?.placement_cost || 0;
  const cost = tariffData?.cost_without_vat || 0;
  const vat = tariffData?.vat_cost || 0;
  const varRate = tariffData?.vat_rate || 0;

  const svgColor = palette.primary.main;

  function onButtonClick() {
    scrollTo('top');
    nextBtnAction();
  }

  const { t } = useTranslations();
  const navigate = useQueryNavigate();

  const isActiveButton = orderNumber && parcelLocker;
  const isMainPage = useMatch('/');

  return (
    <MainContainer>
      <StepsWrapper>
        {stepsData.map(({ name, status }, idx) => {
          const isCompleted = status === 'completed';
          return (
            <StepContainer key={name}>
              <CheckMarkContainer $stepStatus={status} data-first={!idx}>
                {isCompleted && (
                  <SVGIcon name="check-mark" fillVariant="light" />
                )}
              </CheckMarkContainer>
              <StepName>{name}</StepName>
            </StepContainer>
          );
        })}
      </StepsWrapper>

      <TotalCostContainer>
        <SVGContainer>
          <Receipt svgBaseColor={svgColor} />
        </SVGContainer>
        <CostsWrapper>
          <TotalCostDataContainer
            data-active={isExpandedCost}
            role="button"
            aria-label="view cost with vat"
            onClick={() => setIsExpandedCost((prev) => !prev)}
          >
            <TotalCostLabel>{t('total_const_with_vat')}</TotalCostLabel>
            <TotalCostWrapper>
              {isTariffLoading ? (
                <Loader size={22} />
              ) : (
                <TotalCostValue>
                  <span>﷼</span>
                  <span>{totalCost}</span>
                </TotalCostValue>
              )}
              <SVGPaper>
                <SVGIcon name="chevron" size={20} strokeVariant="primary" />
              </SVGPaper>
            </TotalCostWrapper>
          </TotalCostDataContainer>
          <CostAndVat data-active={isExpandedCost}>
            <CostDataContainer>
              <CostLabel>{t('cost')}</CostLabel>
              {isTariffLoading ? (
                <Loader size={20} />
              ) : (
                <CostValue>
                  <span>﷼</span>
                  <span>{cost}</span>
                </CostValue>
              )}
            </CostDataContainer>
            <CostDataContainer>
              <CostLabel>
                {t('vat')}{' '}
                {isTariffLoading ? <Loader size={20} /> : `${varRate}%`}
              </CostLabel>
              {isTariffLoading ? (
                <Loader size={20} />
              ) : (
                <CostValue>
                  <span>﷼</span>
                  <span>{vat}</span>
                </CostValue>
              )}
            </CostDataContainer>
          </CostAndVat>
        </CostsWrapper>
      </TotalCostContainer>

      <ButtonsWrapperStyled
        style={{
          height: 'fit-content',
          marginTop: '0',
        }}
      >
        {isMainPage ? (
          <Button
            disabled={!isActiveButton}
            label={label}
            variant="contained"
            onClick={onButtonClick}
          />
        ) : (
          <>
            <Button
              isLoading={isLoading}
              label={t('back')}
              data-back
              variant="outlined"
              onClick={() => navigate(Path.main)}
              style={{
                width: '40%',
                height: '100%',
              }}
            />
            <Button
              isLoading={isLoading}
              label={label}
              variant="contained"
              onClick={onButtonClick}
            />
          </>
        )}
      </ButtonsWrapperStyled>
    </MainContainer>
  );
}
