import { useCallback } from 'react';
import { useApiContext, UserOmnic } from '@omnipkg/ui-kit-web';
import { useMutation } from '@tanstack/react-query';

const OMNIC_ID_URL = process.env.OMNIC_ID_URL;

interface Args {
  firstName: string;
  lastName: string;
  email: string;
  token: string;
  tokenType: string;
}

interface Request {
  first_name?: string;
  last_name?: string;
  email?: string;
}

function useUpdateUserApi() {
  const { request } = useApiContext();

  return useCallback(
    async (args: Args): Promise<UserOmnic> => {
      const { data } = await request<UserOmnic, Request>({
        method: 'PATCH',
        baseURL: OMNIC_ID_URL,
        url: '/user/current',
        data: {
          first_name: args.firstName,
          last_name: args.lastName,
          email: args.email,
        },
        headers: {
          Authorization: `${args.tokenType} ${args.token}`,
        },
      });

      return data;
    },
    [request],
  );
}

export function useUpdateUser() {
  const updateUserApi = useUpdateUserApi();

  return useMutation<UserOmnic, unknown, Args>({
    mutationFn: updateUserApi,
  });
}
