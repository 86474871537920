import { Button, SVGIcon, useTranslations } from '@omnipkg/ui-kit-web';

import { useBackToSiteButton } from '@src/hooks/useBackToSiteButton';

import {
  ButtonWrapper,
  MainContainer,
  Message,
  TextWrapper,
  Title,
} from './styles';

export default function ErrorPage(): JSX.Element {
  const { t } = useTranslations();

  const { backToSite } = useBackToSiteButton();

  return (
    <MainContainer>
      <SVGIcon name="error" size={100} />
      <TextWrapper>
        <Title>{t('there_was_an_error_order_not_created')}</Title>
        <Message>{t('please_try_again_later')}</Message>
      </TextWrapper>
      <ButtonWrapper>
        <Button
          label={t('back_to_site')}
          variant="contained"
          onClick={backToSite}
        />
      </ButtonWrapper>
    </MainContainer>
  );
}
