import { useCallback } from 'react';
import { useApiContext } from '@omnipkg/ui-kit-web';

const OMNIC_ID_URL = process.env.OMNIC_ID_URL;
const CLIENT_ID = process.env.CLIENT_ID || '';

interface CheckCodeArgs {
  phone: string;
  code: string;
}

interface CheckCodeResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
}

export function useCheckCode() {
  const { request } = useApiContext();

  return useCallback(
    async ({ phone, code }: CheckCodeArgs): Promise<CheckCodeResponse> => {
      const formData = new FormData();
      formData.append('phone', phone);
      formData.append('code', code);
      formData.append('grant_type', 'password');
      formData.append('client_id', CLIENT_ID);

      const data = await request<CheckCodeResponse, FormData>({
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        baseURL: OMNIC_ID_URL,
        url: '/oauth/token',
        data: formData,
      });

      return data as unknown as CheckCodeResponse;
    },
    [request],
  );
}
