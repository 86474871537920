import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { countries } from '../components/CountryPicker/countries';
import { Country, CountryFullData } from '../components/CountryPicker/types';
import { PhoneInputProps } from '../types';
import { formatePhoneNumber } from '../utils/formatePhoneNumber';

interface HookArgs {
  countryData?: Country;
  initialNumber?: string;
  setNumberValue: Dispatch<SetStateAction<string>>;
  sendPhoneData: PhoneInputProps['onChange'];
}

interface HookReturns {
  foundCountry?: CountryFullData;
  changeNumberHandler: (value: string) => void;
}

export function useNumberProcessing({
  countryData,
  initialNumber,
  setNumberValue,
  sendPhoneData,
}: HookArgs): HookReturns {
  const [foundCountry, setFoundCountry] = useState<CountryFullData>();

  useEffect(() => {
    if (!initialNumber) return;

    const processedInitialNumber = initialNumber.includes('+')
      ? initialNumber.slice(1)
      : initialNumber; // +380123456789 -> 380123456789

    const currentCountry = countries
      .filter(({ dialCode, phoneLength }) => {
        const dialCodeWithoutPlus = dialCode.slice(1); // +380 -> 380
        const fullNumberLength = dialCodeWithoutPlus.length + phoneLength;
        return processedInitialNumber.length === fullNumberLength;
      })
      .find(({ dialCode }) => {
        const dialCodeWithoutPlus = dialCode.slice(1);
        return processedInitialNumber.startsWith(dialCodeWithoutPlus);
      });

    if (currentCountry) {
      setFoundCountry(currentCountry);
      const dialCodeWithoutPlus = currentCountry.dialCode.slice(1);
      const initialNumberWithoutDialCode = processedInitialNumber.replace(
        dialCodeWithoutPlus,
        '',
      );

      setNumberValue(initialNumberWithoutDialCode);
    }
  }, [initialNumber, setNumberValue]);

  const changeNumberHandler = useCallback(
    (value: string) => {
      if (!countryData) return;

      const { phoneLength } = countryData;
      const formattedValue = formatePhoneNumber(value);
      const slicedValue = formattedValue.slice(0, phoneLength);

      setNumberValue(slicedValue);
      sendPhoneData?.({
        number: slicedValue,
        fullNumber: `${countryData.dialCode}${slicedValue}`,
        country: countryData,
      });
    },
    [countryData, sendPhoneData, setNumberValue],
  );

  return { foundCountry, changeNumberHandler };
}
