import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing(6)};
  height: 100%;
`;

export const CodeContainer = styled.div`
  position: relative;
  width: 560px;
  max-width: 560px;
  height: 215px;
  max-height: 215px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing()};
  border-radius: ${({ theme }): string => theme.shape.borderRadius.s};
  border: 1px solid ${({ theme }): string => theme.palette.primary.main};

  &::before {
    content: '';
    position: absolute;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    border-radius: ${({ theme }): string => theme.shape.borderRadius.s};
    background-color: ${({ theme }): string =>
      theme.palette.background.primary};
  }

  @media (max-width: ${({ theme }): string => theme.breakpoints.sm}) {
    flex: 1;
    max-width: 100%;
    align-self: auto;
  }
`;

export const Title = styled.h2`
  z-index: 1;
  font-size: ${({ theme }): string => theme.typography.fontSize(32)};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
`;

export const Code = styled.h3`
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  font-size: ${({ theme }): string => theme.typography.fontSize(70)};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[700]};

  @media (max-width: ${({ theme }): string => theme.breakpoints.sm}) {
    font-size: ${({ theme }): string => theme.typography.fontSize(32)};
  }
`;

export const Message = styled.p`
  font-size: ${({ theme }): string => theme.typography.fontSize(32)};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[700]};
  text-align: center;

  @media (max-width: ${({ theme }): string => theme.breakpoints.sm}) {
    font-size: ${({ theme }): string => theme.typography.fontSize(24)};
  }
`;

export const Copy = styled.button`
  cursor: pointer;
  position: absolute;
  left: 100%;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 44px;
  border: none;
  background-color: ${({ theme }): string => theme.palette.grey[10]};
  border-radius: ${({ theme }): string => theme.shape.borderRadius.xxs};
  outline: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background-color: ${({ theme }): string => theme.palette.grey[20]};
    svg {
      path {
        stroke: ${({ theme }): string => theme.palette.grey[100]};
      }
    }
  }

  &:active {
    background-color: ${({ theme }): string => theme.palette.grey[30]};
  }

  @media (max-width: ${({ theme }): string => theme.breakpoints.sm}) {
    height: 32px;
    width: 32px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  width: 266px;
`;
