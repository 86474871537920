import styled from 'styled-components';

import { MOBILE_FOOTER_HEIGHT } from '@src/theme/common';

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 ${({ theme }): string => theme.spacing(3)};
  min-height: 32px;
  border-top: 1px solid ${({ theme }): string => theme.palette.grey[10]};

  @media (max-width: ${({ theme }): string => theme.breakpoints.sm}) {
    min-height: ${MOBILE_FOOTER_HEIGHT}px;
    justify-content: center;
    svg {
      width: auto;
      height: 8px;
      opacity: 0.3;
    }
  }
`;
