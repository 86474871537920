import styled from 'styled-components';

import { Txt1Upper } from '@src/theme/common';

export const HowItWorksContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 68px;
  height: 68px;
  padding: 0 ${({ theme }): string => theme.spacing(2.5)};
  border-radius: ${({ theme }): string => theme.shape.borderRadius.s};
  background-color: ${({ theme }): string => theme.palette.background.primary};
  -webkit-tap-highlight-color: transparent;
`;

export const HowItWorksTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing()};
`;

export const HowItWorksTitle = styled.span`
  ${Txt1Upper};
  font-weight: 700;
`;
