import styled, { css } from 'styled-components';

import { PickerProps } from './types';

function applyPickerVisibility({
  $isShownPicker,
  $pickerPosition,
}: PickerProps) {
  const position = $pickerPosition === 'bottom' ? 'top' : 'bottom';
  return $isShownPicker
    ? css`
        transform: scale(1);
        opacity: 1;
        pointer-events: all;
        ${[position]}: 100%;
      `
    : css`
        transform: scale(0);
        opacity: 0;
        pointer-events: none;
        ${[position]}: 70%;
      `;
}

function applyPickerPosition({ $pickerPosition }: PickerProps) {
  return $pickerPosition === 'bottom'
    ? css`
        margin-top: ${({ theme }) => theme.spacing()};
      `
    : css`
        margin-bottom: ${({ theme }) => theme.spacing()};
      `;
}

export const CountryPickerContainer = styled.div<PickerProps>`
  z-index: 10;
  position: absolute;
  display: flex;
  flex-direction: column;

  width: 100%;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2)};

  box-shadow: 0 0 14px 1px rgba(29, 54, 101, 0.3);
  background-color: ${({ theme }) => theme.palette.background.primary};
  border: 2px solid ${({ theme }) => theme.palette.grey[20]};
  border-radius: 8px;

  transition-duration: ${({ theme }) => theme.transitionDuration.normal};
  transition-property: top, bottom, opacity;
  ${applyPickerVisibility};
  ${applyPickerPosition};
`;

export const CountryList = styled.ul(({ theme }) => {
  const { breakpoints } = theme;
  // Common style
  const commonStyle = css`
    direction: ltr;
    list-style: none;
    width: 100%;
    max-height: 150px;
    display: flex;
    flex-shrink: 1;
    flex-direction: column;
    overflow-y: auto;

    @media (min-width: ${breakpoints.lg}) {
      max-height: 190px;
    }
  `;

  return css`
    ${commonStyle};
  `;
});

export const CountryListItem = styled.li(({ theme }) => {
  const { typography, palette, breakpoints, spacing } = theme;
  // Common style
  const commonStyle = css`
    width: inherit;
    display: flex;
    align-items: center;
    padding: ${spacing()} 0;

    font-size: ${typography.fontSize(16)};
    font-family: ${typography.fontFamily.primary};
    border-bottom: 1px solid ${palette.grey[20]};
    gap: ${spacing()};

    @media (min-width: ${breakpoints.lg}) {
      font-size: ${typography.fontSize(22)};
    }
  `;

  return css`
    ${commonStyle};
  `;
});

export const CountryFlag = styled.span`
  font-size: 1.8em;
`;

export const CountryInfo = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CountryName = styled.span`
  text-align: right;
`;

export const CountryDialCode = styled.span`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;
