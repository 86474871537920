import { Button, useTranslations } from '@omnipkg/ui-kit-web';

import Fifth from './images/fifth.png';
import First from './images/first.png';
import Fourth from './images/fourth.png';
import Second from './images/second.png';
import Third from './images/third.png';
import { Hint, Props } from './types';

import {
  ItemImg,
  ItemTitleWrapper,
  List,
  ListItem,
  Title,
  TopWrapper,
} from './styled';

export default function HowItWorksTab({ closeTab }: Props): JSX.Element {
  const { t } = useTranslations();
  const hints: Hint[] = [
    {
      title: t('step_1'),
      image: First,
    },
    {
      title: t('step_2'),
      image: Second,
    },
    {
      title: t('step_3'),
      image: Third,
    },
    {
      title: t('step_4'),
      image: Fourth,
    },
    {
      title: t('step_5'),
      image: Fifth,
    },
  ];

  return (
    <>
      <TopWrapper>
        <Title>{t('how_it_works')}</Title>
        <List>
          {hints.map((hint, index) => (
            <ListItem key={index}>
              <ItemImg src={hint.image} alt={hint.title} loading="lazy" />
              <ItemTitleWrapper>
                <span>{index + 1}.</span>
                <span>{hint.title}</span>
              </ItemTitleWrapper>
            </ListItem>
          ))}
        </List>
      </TopWrapper>
      <Button
        style={{
          width: '336px',
          marginTop: '16px',
        }}
        label={t('close')}
        variant="contained"
        onClick={closeTab}
      />
    </>
  );
}
