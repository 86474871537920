import { RefObject, useCallback } from 'react';
import { useTranslations } from '@omnipkg/ui-kit-web';

import { InputRef } from '../../Input/types';
import { Country } from '../components/CountryPicker/types';

export function useCheckIsError(
  phoneInputRef: RefObject<InputRef>,
  numberValue: string,
  countryData?: Country,
) {
  const { t } = useTranslations();

  return useCallback(() => {
    if (!countryData) return;

    const { phoneLength } = countryData;
    const isNaN = Number.isNaN(+numberValue);

    if (isNaN) {
      phoneInputRef.current?.setInputError(t('only_numbers'));
      return;
    }
    if (numberValue.length < phoneLength || numberValue.length > phoneLength) {
      phoneInputRef.current?.setInputError(
        `${t('number_length')} ${phoneLength} ${t('characters')}`,
      );
      return;
    }
    return true;
  }, [numberValue, countryData, phoneInputRef, t]);
}
