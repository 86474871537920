import styled, { keyframes } from 'styled-components';

import { Txt2, Txt2Bold } from '@src/theme/common';

export const bounce = keyframes`
  0% {
    transform: translateX(0px);
    animation-timing-function: ease-in;
  }
  37% {
    transform: translateX(5px);
    animation-timing-function: ease-out;
  }
  55% {
    transform: translateX(-5px);
    animation-timing-function: ease-in;
  }
  73% {
    transform: translateX(4px);
    animation-timing-function: ease-out;
  }
  82% {
    transform: translateX(-4px);
    animation-timing-function: ease-in;
  }
  91% {          
    transform: translateX(2px);
    animation-timing-function: ease-out;
  }
  96% {
    transform: translateX(-2px);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    animation-timing-function: ease-in;
  }
`;

export const LrtText = styled.span`
  display: inline-block;
  direction: ltr;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Subtitle = styled.span`
  ${Txt2};
  text-align: center;
  margin-top: ${({ theme }): string => theme.spacing(2)};
`;

export const CodeInputWrapper = styled.div`
  animation-duration: 0.5s;
  animation-delay: 0.25s;

  &[data-bounced='true'] {
    animation-name: ${bounce};
  }
`;

export const ResendCodeButton = styled.button`
  ${Txt2};
  pointer-events: 'none';
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.palette.grey[20]};
  cursor: default;
  -webkit-tap-highlight-color: transparent;
  outline: none;

  &[data-active='true'] {
    pointer-events: auto;
    cursor: pointer;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const NoCodeTextContainer = styled.div`
  display: flex;
  ${Txt2};
  text-align: center;
  gap: ${({ theme }): string => theme.spacing(0.5)};
`;

export const Time = styled.span`
  ${Txt2Bold};
  margin: ${({ theme }): string => theme.spacing()} 0;
`;
