import { useTranslations } from '@omnipkg/ui-kit-web';

import Input from '@src/components/Input';
import PhoneInput from '@src/components/PhoneInput';
import { useAppContext } from '@src/contexts/AppContext/AppContext';
import { Field } from '@src/contexts/AppContext/types';
import { useStore } from '@src/store/store';
import { MainContainer, Title } from '@src/theme/common';

export default function ClientData(): JSX.Element {
  const { t } = useTranslations();
  const { client } = useStore();
  const { emailRef, firstNameRef, lastNameRef, fields, onInputChange } =
    useAppContext();

  return (
    <MainContainer>
      <Title>{t('client_data')}</Title>
      <PhoneInput
        label={t('phone')}
        countryPickerMode="disabled"
        value={client?.phone}
        disabled
        freeWidth
        style={{
          opacity: 0.5,
        }}
      />
      <Input
        required
        ref={firstNameRef}
        label={t('first_name')}
        value={fields.firstName}
        name={Field.FIRST_NAME}
        freeWidth
        onChange={onInputChange}
      />
      <Input
        required
        ref={lastNameRef}
        label={t('last_name')}
        value={fields.lastName}
        name={Field.LAST_NAME}
        freeWidth
        onChange={onInputChange}
      />
      <Input
        ref={emailRef}
        label={t('email')}
        value={fields.email}
        name={Field.EMAIL}
        freeWidth
        onChange={onInputChange}
      />
    </MainContainer>
  );
}
