import { useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonsWrapper,
  CodeInput,
  CodeInputRef,
  useTranslations,
} from '@omnipkg/ui-kit-web';

import { useCheckCodeHandler } from '../../hooks/useCheckCodeHandler';
import {
  CHECK_CODE_DELAY,
  CODE_LENGTH,
  TIME_TO_RESET_STATUS,
} from './constants';
import { Props } from './types';

import { ContentWrapperStyled, Title } from '../../styled';
import {
  CodeInputWrapper,
  LrtText,
  NoCodeTextContainer,
  ResendCodeButton,
  Subtitle,
  Time,
  TitleWrapper,
} from './styled';

export default function Code({
  phone,
  time,
  isCodeGetting,
  getConfirmationCode,
  setAuthStep,
}: Props): JSX.Element {
  const [isBounced, setIsBounced] = useState(false);
  const [code, setCode] = useState('');
  const { t } = useTranslations();

  const codeInputRef = useRef<CodeInputRef>(null);

  const { isCodeChecking, checkCode } = useCheckCodeHandler(phone);

  const isLoading = isCodeGetting || isCodeChecking;

  function onCodeChange(value: string) {
    setCode(value);
  }

  function resendCode() {
    getConfirmationCode(phone);
  }

  function onNextClick() {
    if (code.length !== CODE_LENGTH) {
      setIsBounced(true);
      return;
    }
    checkCode(code);
  }

  function onBackClickHandler() {
    setAuthStep('phone');
  }

  useEffect(() => {
    if (!isBounced) return;

    const timeout = setTimeout(() => {
      setIsBounced(false);
    }, TIME_TO_RESET_STATUS);

    return () => clearTimeout(timeout);
  }, [isBounced]);

  useEffect(() => {
    if (code.length === CODE_LENGTH) {
      setTimeout(() => {
        checkCode(code);
      }, CHECK_CODE_DELAY);
    }
  }, [code, checkCode]);

  return (
    <>
      <ContentWrapperStyled>
        <TitleWrapper>
          <Title>{t('enter_your_phone_number')}</Title>
          <Subtitle>
            {t('code_was_sent_to')} <LrtText>{phone}</LrtText>
          </Subtitle>
        </TitleWrapper>

        <CodeInputWrapper data-bounced={isBounced}>
          <CodeInput
            ref={codeInputRef}
            onChangeCode={onCodeChange}
            codeLength={CODE_LENGTH}
            disabled={isCodeChecking}
          />
        </CodeInputWrapper>

        <TitleWrapper>
          <NoCodeTextContainer>
            <span>{t('no_code')}</span> <span>{t('click_resend_code')}:</span>
          </NoCodeTextContainer>
          <Time>{time || '0'}</Time>
          <ResendCodeButton
            disabled={!!time}
            data-active={!time}
            onClick={resendCode}
          >
            {t('resend_code')}
          </ResendCodeButton>
        </TitleWrapper>
      </ContentWrapperStyled>
      <ButtonsWrapper>
        <Button
          isLoading={isLoading}
          label={t('back')}
          variant="outlined"
          onClick={onBackClickHandler}
        />
        <Button
          isLoading={isLoading}
          label={t('next')}
          variant="contained"
          onClick={onNextClick}
        />
      </ButtonsWrapper>
    </>
  );
}
