import styled, { css } from 'styled-components';

import {
  InputContainerProps,
  InputWrapperProps,
  PhoneCodeWrapperProps,
  TextInputProps,
} from './types';

interface LabelProps {
  $isLabelShown: boolean;
  $isError: boolean;
}

interface InfoTextProps {
  $isError: boolean;
}

export const Label = styled.label<LabelProps>(
  ({ theme, $isLabelShown, $isError }) => {
    const { typography, palette, transitionDuration, spacing } = theme;
    const applyLabelStyle = $isLabelShown
      ? css`
          color: ${palette.grey[30]};
          transform: translateY(20px);
          font-size: ${typography.fontSize(16)};
        `
      : css`
          color: ${$isError ? palette.error : ''};
          transform: translateY(-1.3em);
          font-size: ${typography.fontSize(12)};
        `;
    // Common style
    const commonStyle = css`
      align-self: flex-start;
      margin: 0 ${spacing(2.5)};
      position: absolute;
      display: block;
      pointer-events: none;
      transition-duration: ${transitionDuration.normal};
      ${applyLabelStyle};
    `;

    // Web style
    return css`
      ${commonStyle};
    `;
  },
);

export const InfoContainer = styled.div`
  height: fit-content;
  position: absolute;
  top: 100%;
  margin-top: ${({ theme }): string => theme.spacing(0.5)};
  gap: ${({ theme }): string => theme.spacing(0.5)};
`;

export const InfoText = styled.span<InfoTextProps>(({ theme, $isError }) => {
  const { typography, palette } = theme;
  // Common style
  const commonStyle = css`
    color: ${$isError ? palette.error : palette.grey[30]};
    font-size: ${typography.fontSize(12)};
  `;

  return css`
    ${commonStyle};
  `;
});

export const InputWrapper = styled.div<InputWrapperProps>(
  ({ theme, $isSelected, $isRtlInput }) => {
    const { palette, shape, spacing } = theme;
    const applyDirection =
      $isRtlInput &&
      css`
        direction: rtl;
        text-align: right;
      `;

    return css`
      display: flex;
      flex-grow: 1;
      align-items: center;
      gap: ${spacing(1.5)};
      border-width: 1px;
      border-style: solid;
      border-color: ${$isSelected ? palette.primary.main : palette.grey[10]};
      border-radius: ${shape.borderRadius.s};
      direction: ltr;
      text-align: left;
      ${applyDirection};
    `;
  },
);

export const PhoneCodeWrapper = styled.button<PhoneCodeWrapperProps>`
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing()};
  cursor: ${({ $isActive }): string => ($isActive ? 'pointer' : 'default')};
`;

export const SVGWrapper = styled.span(() => {
  return css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      position: absolute;
      width: inherit;
      padding-top: 100%;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.palette.primary.main};
      z-index: 1;
    }
    svg {
      position: relative;
      z-index: 2;
      path {
        stroke: ${({ theme }) => theme.palette.text.secondary};
      }
    }
  `;
});

export const PhoneCode = styled.span``;

export const TextInput = styled.input<TextInputProps>(({ theme }) => {
  const { palette } = theme;
  // Common style
  const commonStyle = css`
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;

    &::placeholder {
      color: ${palette.grey[40]};
    }
  `;

  return css`
    ${commonStyle};
  `;
});

export const InputContainer = styled.div<InputContainerProps>(
  ({ theme, $freeWidth, $isError }) => {
    const { typography, palette, breakpoints, spacing } = theme;
    const applyErrorStyles =
      $isError &&
      css`
        ${InputWrapper} {
          border-color: ${palette.error};
        }
      `;

    // Common style
    const commonStyle = css`
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;

      ${InputWrapper} {
        padding: ${`${spacing(2.25)} ${spacing(2)}`};
      }
      ${PhoneCode}, ${TextInput} {
        font-size: ${typography.fontSize(16)};
      }
      ${SVGWrapper} {
        width: 18px;
        height: 18px;
        svg {
          width: 12px;
          height: 12px;
        }
      }

      @media (min-width: ${breakpoints.md}) {
        max-width: ${$freeWidth ? '100%' : '360px'};
      }

      ${applyErrorStyles};
    `;

    return css`
      ${commonStyle};
    `;
  },
);

export const PasswordEye = styled.button`
  position: relative;
  cursor: pointer;

  border: none;
  outline: none;
  background-color: transparent;

  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* For svg container */
  span {
    position: absolute;
  }
`;
