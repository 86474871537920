import {
  ForwardedRef,
  forwardRef,
  MouseEvent,
  useEffect,
  useId,
  useState,
} from 'react';
import { SVGIcon, useTranslations } from '@omnipkg/ui-kit-web';

import { DEFAULT_ERROR } from './constants';
import { useInputRef } from './hooks/useInputRef';
import { InputError, InputProps, InputRef } from './types';

import {
  InfoContainer,
  InfoText,
  InputContainer,
  InputWrapper,
  Label,
  PasswordEye,
  PhoneCode,
  PhoneCodeWrapper,
  SVGWrapper,
  TextInput,
} from './styled';

const Input = forwardRef(
  (
    {
      label,
      isPassword,
      infoMessage,
      freeWidth,
      selected,
      nativeRef,
      phoneManager,
      value,
      type = 'text',
      required = false,
      minPasswordLength = 6,
      onClick,
      onFocus,
      onBlur,
      ...inputProps
    }: InputProps,
    ref?: ForwardedRef<InputRef>,
  ): JSX.Element => {
    const id = useId();
    const [isShownPassword, setIsShownPassword] = useState(false);
    const [isOnFocus, setIsOnFocus] = useState(false);
    const [{ isError, errorMessage }, setError] =
      useState<InputError>(DEFAULT_ERROR);

    const phoneCode = phoneManager?.phoneCode;
    const isOpenPicker = phoneManager?.isOpenPicker;
    const isInteractivePhoneCode = !!phoneManager?.onPhoneCodeClick;
    const onPhoneCodeClick = phoneManager?.onPhoneCodeClick;

    const isLabelShown = !isOnFocus && !value?.trim() && !phoneCode;
    const isShowInputExtraInfo = !!infoMessage || (isError && !!errorMessage);

    const { isRTL } = useTranslations();

    const isRtlInput = isRTL && !phoneCode;

    useEffect(() => {
      setError(DEFAULT_ERROR);
    }, [value, phoneCode]);

    const innerRef = useInputRef({
      ref,
      value,
      required,
      minPasswordLength,
      setError,
    });

    const eyeIcon = (
      <PasswordEye
        onClick={() => setIsShownPassword((prevState) => !prevState)}
      >
        {isShownPassword ? <SVGIcon name="eye" /> : <SVGIcon name="eye-off" />}
      </PasswordEye>
    );

    function handleOnClick(event: MouseEvent<HTMLInputElement>) {
      onClick?.(event);
      innerRef.current?.style?.removeProperty('caret-color');
    }

    function handleOnFocus(event: React.FocusEvent<HTMLInputElement>) {
      onFocus?.(event);
      setIsOnFocus(true);
    }

    function handleOnBlur(event: React.FocusEvent<HTMLInputElement>) {
      onBlur?.(event);
      setIsOnFocus(false);
    }

    return (
      <InputContainer ref={nativeRef} $isError={isError} $freeWidth={freeWidth}>
        {label && (
          <Label htmlFor={id} $isLabelShown={isLabelShown} $isError={isError}>
            {label} {required && '*'}
          </Label>
        )}
        <InputWrapper
          $isSelected={selected || isOnFocus}
          $isRtlInput={isRtlInput}
        >
          {phoneCode && (
            <PhoneCodeWrapper
              $isActive={isInteractivePhoneCode}
              onClick={onPhoneCodeClick}
            >
              {isInteractivePhoneCode && (
                <SVGWrapper>
                  <SVGIcon name="chevron" rotateX={isOpenPicker ? 180 : 0} />
                </SVGWrapper>
              )}
              <PhoneCode>{phoneCode}</PhoneCode>
            </PhoneCodeWrapper>
          )}
          <TextInput
            ref={innerRef}
            id={id}
            onClick={handleOnClick}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            value={value}
            type={isPassword && !isShownPassword ? 'password' : type}
            required={required}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="none"
            $isLabelShown={isLabelShown}
            {...inputProps}
          />
          {isPassword && eyeIcon}
        </InputWrapper>
        {isShowInputExtraInfo && (
          <InfoContainer>
            <InfoText $isError={isError} className="input-extra-text">
              {errorMessage || infoMessage}
            </InfoText>
          </InfoContainer>
        )}
      </InputContainer>
    );
  },
);

Input.displayName = 'Input';
export default Input;
