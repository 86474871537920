import styled, { css } from 'styled-components';

import { PhoneInputContainerProps } from './types';

export const PhoneInputContainer = styled.div<PhoneInputContainerProps>(
  ({ theme, $freeWidth }) => {
    const { breakpoints, spacing } = theme;

    const commonStyle = css`
      width: 100%;
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: ${spacing(4)};

      @media (min-width: ${breakpoints.lg}) {
        max-width: ${$freeWidth ? '100%' : '360px'};
      }
    `;

    return css`
      ${commonStyle}
    `;
  },
);

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;
