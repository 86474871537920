import { ChangeEvent, useEffect, useState } from 'react';
import { DeliveryPoint, LocationPoint, Widget } from '@omnic/widget-locations';
import { Checkbox, useTranslations } from '@omnipkg/ui-kit-web';
import { useTheme } from 'styled-components';

import { useGetParcelLockers } from '@src/api/getParcelLockers';
import Input from '@src/components/Input';
import { useAppContext } from '@src/contexts/AppContext/AppContext';
import { useStore } from '@src/store/store';
import { MainContainer, Title } from '@src/theme/common';
import { scrollTo } from '@src/utils/scrollToTop';

import ProhibitedGoods from './components/ProhibitedGoods';
import Searcher from './components/Searcher';
import {
  CENTER,
  INITIAL_ZOOM_MAP,
  MAP_LOADING_DELAY,
  WIDGET_BASE_URL,
} from './constants';

import '@omnic/widget-locations/dist/lib/style.css';

import { InputWrapper, MapContainer, MapWrapper } from './styled';

export default function OrderInformation(): JSX.Element {
  const [lockerValue, setLockerValue] = useState<string | undefined>(undefined);

  const { t, langData } = useTranslations();
  const { palette } = useTheme();
  const {
    orderNumber,
    parcelLocker,
    isMapOpen,
    setOrderNumber,
    setParcelLocker,
    setIsMapOpen,
  } = useStore();
  const { orderNumberRef, parcelLockerRef } = useAppContext();
  const { data, isLoading, refetch } = useGetParcelLockers(lockerValue || '');

  const primaryColor = palette.primary.main;
  const secondaryColor = palette.primary[80];

  const parcelLockers = data?.results;

  useEffect(() => {
    if (typeof lockerValue === 'undefined') return;

    if (lockerValue.length < 3) {
      setParcelLocker(null);
      return;
    }

    const timeout = setTimeout(() => {
      refetch();
    }, 1000);

    return () => clearTimeout(timeout);
  }, [lockerValue, refetch, setParcelLocker]);

  useEffect(() => {
    if (parcelLocker) {
      setLockerValue(undefined);
    }
  }, [parcelLocker]);

  function onCheckboxChange(event: ChangeEvent<HTMLInputElement>) {
    const { checked } = event.target;
    setIsMapOpen(checked);

    if (checked) {
      setTimeout(() => {
        scrollTo('bottom');
      }, MAP_LOADING_DELAY);
    }
  }

  function onPointClick(point: LocationPoint | DeliveryPoint) {
    setParcelLocker(point as LocationPoint);
    setIsMapOpen(false);
  }

  return (
    <>
      <ProhibitedGoods />
      <MainContainer>
        <Title>{t('order_info')}</Title>
        <Input
          required
          ref={orderNumberRef}
          label={t('order_number')}
          freeWidth
          value={orderNumber}
          onChange={(event) => setOrderNumber(event.target.value)}
        />
        <InputWrapper>
          <Input
            disabled={isMapOpen}
            required
            ref={parcelLockerRef}
            label={t('parcel_locker')}
            freeWidth
            value={lockerValue ?? parcelLocker?.name}
            onChange={(event) => setLockerValue(event.target.value)}
            onFocus={() => scrollTo('bottom')}
            style={{
              opacity: isMapOpen ? 0.4 : 1,
            }}
          />
          <Searcher
            inputValue={lockerValue || ''}
            isLoading={isLoading}
            parcelLockers={parcelLockers}
          />
        </InputWrapper>
        <Checkbox checked={isMapOpen} onChange={onCheckboxChange}>
          {t('set_location_on_the_map')}
        </Checkbox>
        {isMapOpen && (
          <MapContainer>
            <MapWrapper>
              <div id="@omnic/widget-locations">
                <Widget
                  config={{
                    startScreen: 'map',
                    hideFilters: true,
                    hideLogo: true,
                    hideHeader: true,
                    INN: process.env.INN as string,
                    locale: langData.lang,
                    palette: {
                      primary: primaryColor,
                      'primary-dark': secondaryColor,
                    },
                    map: {
                      center: CENTER,
                      initialZoom: INITIAL_ZOOM_MAP,
                      provider: 'google',
                    },
                    disableGeolocation: true,
                    disableDelivery: true,
                    radius: 2,
                    font: 'Roboto',
                    onPointClick: onPointClick,
                  }}
                  options={{
                    baseURL: `${WIDGET_BASE_URL}/type-1/public/`,
                  }}
                />
              </div>
            </MapWrapper>
          </MapContainer>
        )}
      </MainContainer>
    </>
  );
}
