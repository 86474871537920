import { useCallback } from 'react';
import { useApiContext } from '@omnipkg/ui-kit-web';

const OMNIC_ID_URL = process.env.OMNIC_ID_URL;
const CLIENT_ID = process.env.CLIENT_ID || '';

interface Request {
  phone: string;
  client_id: string;
}

export function useGetConfirmationCode() {
  const { request } = useApiContext();

  return useCallback(
    async (phone: string): Promise<void> => {
      await request<never, Request>({
        method: 'POST',
        baseURL: OMNIC_ID_URL,
        url: '/phone/verification/code/send',
        data: {
          phone,
          client_id: CLIENT_ID,
        },
      });
    },
    [request],
  );
}
