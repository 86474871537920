import {
  ChangeEvent,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMatch, useSearchParams } from 'react-router-dom';
import {
  InputRef,
  Lang,
  useErrorHandlerContext,
  useQueryNavigate,
  useTranslations,
} from '@omnipkg/ui-kit-web';
import { z } from 'zod';

import { useGetTariff } from '@src/api/getTariff';
import { SideTabRef } from '@src/components/SideTab/types';
import { useStore } from '@src/store/store';
import { Path } from '@src/types/routes';

import { DEFAULT_FIELDS } from './constants';
import { AppContextValue, ConfirmActions, Props, UserFields } from './types';
import { useCreateOrder } from './utils/createOrder';
import { useUpdateUser } from './utils/updateUser';

const langSchema = z.enum(['en', 'ar']);

const AppContext = createContext<AppContextValue | null>(null);

export function AppContextProvider({ children }: Props): JSX.Element {
  const [isRegistrationAvailable, setIsRegistrationAvailable] = useState(false);
  const [confirmActions, setConfirmActions] = useState<ConfirmActions>();
  const [fields, setField] = useState<UserFields>(DEFAULT_FIELDS);

  const { t } = useTranslations();

  const firstNameRef = useRef<InputRef>(null);
  const lastNameRef = useRef<InputRef>(null);
  const emailRef = useRef<InputRef>(null);
  const orderNumberRef = useRef<InputRef>(null);
  const parcelLockerRef = useRef<InputRef>(null);
  const prohibitedGoodsRef = useRef<SideTabRef>(null);

  const isMainPage = useMatch('/');
  const [searchParams] = useSearchParams();

  const lang = searchParams.get('lang');

  const { setDefaultLang } = useTranslations();

  const {
    parcelLocker,
    client,
    authData,
    orderNumber,
    setIsRegistrationOpen,
    setClient,
    setOrderData,
  } = useStore();
  const { data, isPending: isTariffLoading } = useGetTariff();
  const { isPending: isUserUpdating, mutate: updateUser } = useUpdateUser();
  const { isPending: isOrderCreating, mutate: createOrder } = useCreateOrder();
  const { handleError } = useErrorHandlerContext();

  const isCreating = isOrderCreating || isUserUpdating;

  const navigate = useQueryNavigate();

  const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setField((prev) => ({ ...prev, [name]: value }));
  }, []);

  const createOrderHandler = useCallback(() => {
    if (!authData || !parcelLocker || !orderNumber) return;
    createOrder(
      {
        orderNumber: orderNumber,
        recipientPoint: parcelLocker.code,
        token: authData.accessToken,
        tokenType: authData.accessToken,
      },
      {
        onSuccess: (orderData) => {
          setOrderData(orderData);
          if (!orderData.placement_code) {
            throw new Error('Placement code is not provided');
          }
          navigate(Path.placementCode);
        },
        onError: (error) => {
          console.error(error);
          navigate(Path.error);
        },
      },
    );
  }, [
    parcelLocker,
    orderNumber,
    authData,
    createOrder,
    setOrderData,
    navigate,
  ]);

  const requestConfirmation = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      if (isRegistrationAvailable) {
        return resolve();
      }

      prohibitedGoodsRef.current?.open();

      setConfirmActions({
        apply: resolve,
        cancel: reject,
      });
    });
  }, [isRegistrationAvailable]);

  const authUser = useCallback(async () => {
    const isEmptyOrderNumber = orderNumberRef.current?.isEmpty();

    if (!parcelLocker) {
      parcelLockerRef.current?.setInputError('Choose parcel locker');
    }

    if (isEmptyOrderNumber || !parcelLocker) return;

    try {
      await requestConfirmation();
      setIsRegistrationOpen(true);
    } catch {
      return;
    }
  }, [
    parcelLocker,
    orderNumberRef,
    parcelLockerRef,
    requestConfirmation,
    setIsRegistrationOpen,
  ]);

  const getPlacementCode = useCallback(() => {
    const isEmptyFirstName = firstNameRef.current?.isEmpty();
    const isEmptyLastName = lastNameRef.current?.isEmpty();
    const isEmailValid = emailRef.current?.isEmailValid();

    if (
      isEmptyFirstName ||
      isEmptyLastName ||
      !isEmailValid ||
      !authData ||
      !parcelLocker
    ) {
      return null;
    }

    const isFirstNameEqual = fields.firstName === client?.first_name;
    const isLastNameEqual = fields.lastName === client?.last_name;
    const isEmailEqual = fields.email === client?.email;

    if (!isFirstNameEqual || !isLastNameEqual || !isEmailEqual) {
      updateUser(
        {
          email: fields.email,
          firstName: fields.firstName,
          lastName: fields.lastName,
          token: authData.accessToken,
          tokenType: authData.tokenType,
        },
        {
          onSuccess: (clientData) => {
            setClient(clientData);

            createOrderHandler();
          },
          onError: (error) => {
            const errorData = (error as AnyType).stack;

            if (
              errorData?.ids === 'already_exist' &&
              errorData?.message.includes('email')
            ) {
              emailRef.current?.setInputError(t('email_already_exists'));
            } else {
              handleError((error as AnyType).message);
            }
          },
        },
      );
    } else {
      createOrderHandler();
    }
  }, [
    fields,
    client,
    authData,
    emailRef,
    lastNameRef,
    firstNameRef,
    parcelLocker,
    t,
    setClient,
    updateUser,
    handleError,
    createOrderHandler,
  ]);

  useEffect(() => {
    if (client) {
      setField({
        firstName: client.first_name || '',
        lastName: client.last_name || '',
        email: client.email || '',
      });
    }
  }, [client]);

  useEffect(() => {
    if (!isMainPage && !authData?.accessToken) {
      navigate(Path.main);
    }
  }, [authData, isMainPage, navigate]);

  useEffect(() => {
    if (lang && langSchema.safeParse(lang).success) {
      setDefaultLang(lang as Lang);
    } else {
      setDefaultLang('en');
    }
  }, [lang, setDefaultLang]);

  const value: AppContextValue = useMemo(
    () => ({
      firstNameRef,
      lastNameRef,
      emailRef,
      orderNumberRef,
      parcelLockerRef,
      tariffData: data,
      isTariffLoading,
      fields,
      isCreating,
      confirmActions,
      prohibitedGoodsRef,
      setIsRegistrationAvailable,
      onInputChange,
      authUser,
      getPlacementCode,
    }),
    [
      firstNameRef,
      lastNameRef,
      emailRef,
      orderNumberRef,
      parcelLockerRef,
      data,
      isTariffLoading,
      fields,
      isCreating,
      confirmActions,
      prohibitedGoodsRef,
      setIsRegistrationAvailable,
      onInputChange,
      authUser,
      getPlacementCode,
    ],
  );
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export function useAppContext(): AppContextValue {
  const value = useContext(AppContext);

  if (value) return value;

  throw new Error('Please, use useAppContext hook inside AppContextProvider');
}
