import { useCallback } from 'react';
import { useApiContext, UserOmnic } from '@omnipkg/ui-kit-web';

const OMNIC_ID_URL = process.env.OMNIC_ID_URL;

interface Args {
  token: string;
  tokenType: string;
}

export function useGetUserInfo() {
  const { request } = useApiContext();

  return useCallback(
    async ({ token, tokenType }: Args): Promise<UserOmnic> => {
      const data = await request<UserOmnic>({
        headers: {
          Authorization: `${tokenType} ${token}`,
        },
        baseURL: OMNIC_ID_URL,
        url: '/user/base/info',
      });

      return data as unknown as UserOmnic;
    },
    [request],
  );
}
