import { CONTENT_ID } from '@src/constants/common';

export function scrollTo(direction: 'top' | 'bottom'): void {
  const contentContainer = document.getElementById(CONTENT_ID);

  if (!contentContainer) return;

  const scrollHeight = contentContainer.scrollHeight;
  const top = direction === 'top' ? 0 : scrollHeight;

  contentContainer?.scrollTo({
    top,
    behavior: 'smooth',
  });
}
