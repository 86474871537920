import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import {
  ApiContextProvider,
  ErrorHandlerContextProvider,
  NotificationsContextProvider,
  ThemeContextProvider,
  ThemeIndex,
  TranslationContextProvider,
} from '@omnipkg/ui-kit-web';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import App from '@src/App';

const queryClient = new QueryClient();

const HUB_API = process.env.HUB_API;
const TOLGEE_API_KEY = process.env.TOLGEE_API_KEY;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <ThemeContextProvider isPrimary theme={ThemeIndex.OMNI_LLAMA}>
        <TranslationContextProvider
          tolgeeApiKey={TOLGEE_API_KEY}
          projectLangs={['en', 'ar']}
        >
          <NotificationsContextProvider>
            <ErrorHandlerContextProvider>
              <ApiContextProvider baseURL={HUB_API || ''}>
                <App />
              </ApiContextProvider>
            </ErrorHandlerContextProvider>
          </NotificationsContextProvider>
        </TranslationContextProvider>
      </ThemeContextProvider>
    </QueryClientProvider>
  </BrowserRouter>,
);
