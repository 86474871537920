import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing(5)};
  height: 100%;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }): string => theme.spacing()};

  @media (max-width: ${({ theme }): string => theme.breakpoints.sm}) {
    padding: 0 ${({ theme }): string => theme.spacing(2.5)};
  }
`;

export const Title = styled.h2`
  text-align: center;
  font-size: ${({ theme }): string => theme.typography.fontSize(32)};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[700]};

  @media (max-width: ${({ theme }): string => theme.breakpoints.sm}) {
    font-size: ${({ theme }): string => theme.typography.fontSize(24)};
  }
`;

export const Message = styled.p`
  font-size: ${({ theme }): string => theme.typography.fontSize(18)};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  width: 266px;
`;
