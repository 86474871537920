import { useRoutes as useBaseRoutes } from 'react-router-dom';

import MainLayout from '@src/layouts/MainLayout';
import ProcessLayout from '@src/layouts/ProcessLayout';
import ClientData from '@src/pages/ClientData';
import ErrorPage from '@src/pages/Error/ErrorPage';
import OrderInformation from '@src/pages/OrderInformation';
import PlacementCode from '@src/pages/PlacementCode';
import { Path } from '@src/types/routes';

export function useRoutes() {
  return useBaseRoutes([
    {
      path: Path.main,
      element: <MainLayout />,
      children: [
        {
          element: <ProcessLayout />,
          children: [
            {
              index: true,
              element: <OrderInformation />,
            },
            {
              path: Path.clientData,
              element: <ClientData />,
            },
          ],
        },
        {
          path: Path.placementCode,
          element: <PlacementCode />,
        },
        {
          path: Path.error,
          element: <ErrorPage />,
        },
      ],
    },
  ]);
}
