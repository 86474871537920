import styled from 'styled-components';

import { Txt1, Txt1Bold, Txt2 } from '@src/theme/common';

export const SearcherContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 100%;
  margin-top: ${({ theme }) => theme.spacing()};
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-radius: ${({ theme }) => theme.shape.borderRadius.s};
  box-shadow: 0px 4px 16px 0px rgba(29, 54, 101, 0.1);
  min-height: 84px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 2;
`;

export const SearcherList = styled.ul`
  align-self: flex-start;
  width: 100%;
  height: 100%;
`;

export const SearcherItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing()};
  list-style-type: none;
  height: 84px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.graphite[20]};
  padding: ${({ theme }) => theme.spacing(2)};
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[10]};
  }
`;

export const ParcelLockerName = styled.span`
  ${Txt1Bold};
`;

export const ParcelLockerAddress = styled.span`
  ${Txt2};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${({ theme }) => theme.typography.fontSize(12)};
  }
`;

export const NoData = styled.span`
  ${Txt1};
  font-style: italic;
  color: ${({ theme }) => theme.palette.grey[20]};
`;
