import { MainContainer, RootContainer } from '@omnipkg/ui-kit-web';
import { styled } from 'styled-components';

import { MOBILE_FOOTER_HEIGHT, MOBILE_HEADER_HEIGHT } from '@src/theme/common';

const HEADER_FOOTER_MOBILE_HEIGHT = MOBILE_HEADER_HEIGHT + MOBILE_FOOTER_HEIGHT;

export const RootContainerStyled = styled(RootContainer)`
  background-color: ${({ theme }): string => theme.palette.primary[5]};
`;

export const MainContainerStyled = styled(MainContainer)`
  padding: ${({ theme }): string => theme.spacing(3.5)};
  align-items: center;
  overflow-y: auto;

  @media (max-width: ${({ theme }): string => theme.breakpoints.sm}) {
    padding: 0;
    overflow-y: visible;

    @supports (height: 100dvh) {
      max-height: calc(100dvh - ${HEADER_FOOTER_MOBILE_HEIGHT}px);
    }
    max-height: calc(100vh - ${HEADER_FOOTER_MOBILE_HEIGHT}px);
  }
`;
