import { useRef } from 'react';
import { Button, PhoneInputRef, useTranslations } from '@omnipkg/ui-kit-web';

import PhoneInput from '@src/components/PhoneInput';

import { Props } from './types';

import { ContentWrapperStyled, Title } from '../../styled';

export default function PhoneNumber({
  time,
  isCodeGetting,
  setPhone,
  setAuthStep,
  getConfirmationCode,
}: Props): JSX.Element {
  const { t } = useTranslations();

  const phoneInputRef = useRef<PhoneInputRef>(null);

  const isDisabled = !!time;

  const buttonLabel = time ? `${t('next')} (${time})` : t('next');

  function onNextClick() {
    const phoneData = phoneInputRef.current?.getPhoneInfo();

    if (!phoneData?.isNumberCorrect) return;

    const { phoneNumber } = phoneData;

    getConfirmationCode(phoneNumber).then((success) => {
      if (!success) return;
      setAuthStep('code');
      setPhone(phoneNumber);
    });
  }

  return (
    <>
      <ContentWrapperStyled>
        <Title>{t('enter_your_phone_number')}</Title>
        <PhoneInput
          ref={phoneInputRef}
          countryPickerMode="onlyNumber"
          freeWidth
        />
      </ContentWrapperStyled>
      <Button
        isLoading={isCodeGetting}
        disabled={isDisabled}
        label={buttonLabel}
        variant="contained"
        onClick={onNextClick}
      />
    </>
  );
}
