import { ContentWrapper } from '@omnipkg/ui-kit-web';
import styled from 'styled-components';

import { H2 } from '@src/theme/common';

export const Paper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex: 1;
  padding: 0 ${({ theme: { spacing } }) => `${spacing(7)}`};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0 ${({ theme: { spacing } }) => `${spacing(2.5)}`};
  }
`;

export const ContentWrapperStyled = styled(ContentWrapper)`
  gap: ${({ theme }) => theme.spacing(7)};
`;

export const Title = styled.h2`
  ${H2};
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${({ theme }) => theme.typography.fontSize(18)};
    font-weight: 400;
  }
`;
