import { useLocation } from 'react-router-dom';
import { useTranslations } from '@omnipkg/ui-kit-web';

import { useAppContext } from '@src/contexts/AppContext/AppContext';
import { Path } from '@src/types/routes';

export type StepStatus = 'active' | 'completed' | 'future';

interface NextButtonData {
  isLoading?: boolean;
  label: string;
  nextBtnAction: () => void;
}

interface StepData {
  name: string;
  status: StepStatus;
}

interface HookReturns {
  stepsData: StepData[];
  getNextButtonData: () => NextButtonData;
}

export function useStepsHandler(): HookReturns {
  const { pathname: currentPath } = useLocation();

  const { isCreating, authUser, getPlacementCode } = useAppContext();

  const { t } = useTranslations();

  function getStepStatus(stepPath: Path | Path[]): StepStatus {
    if (stepPath.includes(currentPath as Path)) {
      return 'active';
    }
    // TODO: change this logic
    if (currentPath > stepPath) {
      return 'completed';
    }
    return 'future';
  }

  const stepsData: StepData[] = [
    {
      name: t('order_info'),
      status: getStepStatus(Path.main),
    },
    {
      name: t('client_details'),
      status: getStepStatus([Path.clientData, Path.error, Path.placementCode]),
    },
  ];

  function getNextButtonData(): NextButtonData {
    switch (currentPath) {
      case Path.main:
        return {
          label: t('create_order'),
          nextBtnAction: authUser,
        };
      default:
        return {
          isLoading: isCreating,
          label: t('get_placement_code'),
          nextBtnAction: getPlacementCode,
        };
    }
  }
  return { stepsData, getNextButtonData };
}
