import { styled } from 'styled-components';

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: ${({ theme }): string => theme.spacing(3)};

  @media (max-width: ${({ theme }): string => theme.breakpoints.sm}) {
    gap: 0;
    flex-direction: column;
    height: 100%;
    max-height: inherit;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 668px;
  flex-grow: 1;
  gap: ${({ theme }): string => theme.spacing(3)};

  @media (max-width: ${({ theme }): string => theme.breakpoints.sm}) {
    gap: ${({ theme }): string => theme.spacing(2)};
    padding: ${({ theme }): string => theme.spacing(2)};
    flex-shrink: 1;
    overflow-y: auto;
  }
`;
