import { useSearchParams } from 'react-router-dom';
import { useQueryNavigate } from '@omnipkg/ui-kit-web';

import { Path } from '@src/types/routes';

export function useBackToSiteButton() {
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirect_url');

  const navigate = useQueryNavigate();

  function clearSessionStorage() {
    sessionStorage.clear();
  }

  function backToSite() {
    clearSessionStorage();

    if (redirectUrl) {
      window.location.replace(redirectUrl);
    } else {
      navigate(Path.main);
    }
  }

  return { backToSite };
}
