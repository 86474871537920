import { Button, SVGIcon, useTranslations } from '@omnipkg/ui-kit-web';

import { useAppContext } from '@src/contexts/AppContext/AppContext';

import { ProhibitedItem, Props } from './types';

import {
  GoodsContainer,
  ProhibitedItemContainer,
  ProhibitedItemName,
  ProhibitedItemSVGWrapper,
  Title,
  TopWrapper,
} from './styled';

export default function ProhibitedGoodsTab({ closeTab }: Props): JSX.Element {
  const { t } = useTranslations();
  const { confirmActions, setIsRegistrationAvailable } = useAppContext();

  const prohibitedItems: ProhibitedItem[] = [
    {
      name: t('poison'),
      svg: 'prohibition-radioactive',
    },
    {
      name: t('explosive_items'),
      svg: 'prohibition-explosive',
    },
    {
      name: t('weapons'),
      svg: 'prohibition-weapon',
    },
    {
      name: t('money'),
      svg: 'prohibition-money',
    },
    {
      name: t('perishable_products'),
      svg: 'prohibition-food',
    },
    {
      name: t('drugs'),
      svg: 'prohibition-drugs',
    },
  ];

  function onConfirmClick() {
    setIsRegistrationAvailable(true);
    confirmActions?.apply();
    closeTab();
  }

  return (
    <>
      <TopWrapper>
        <Title>{t('confirm_prohibited_items_title')}</Title>
        <GoodsContainer>
          {prohibitedItems.map((item) => (
            <ProhibitedItemContainer key={item.name}>
              <ProhibitedItemSVGWrapper>
                <SVGIcon name={item.svg} size={80} />
              </ProhibitedItemSVGWrapper>
              <ProhibitedItemName>{item.name}</ProhibitedItemName>
            </ProhibitedItemContainer>
          ))}
        </GoodsContainer>
      </TopWrapper>
      <Button
        style={{
          width: '336px',
          marginTop: '16px',
        }}
        label={t('i_confirm')}
        variant="contained"
        onClick={onConfirmClick}
      />
    </>
  );
}
