import styled from 'styled-components';

import { H2, Txt1 } from '@src/theme/common';

export const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  flex-shrink: 1;
`;

export const Title = styled.h2`
  ${H2};
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${({ theme }) => theme.typography.fontSize(18)};
    font-weight: 400;
  }
`;

export const List = styled.ul`
  margin-top: ${({ theme }) => theme.spacing(2.5)};
  width: 100%;
`;

export const ListItem = styled.li`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.graphite[20]};
  padding-top: ${({ theme }) => theme.spacing(2.5)};
  overflow: hidden;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }

  &:nth-child(2) {
    img {
      scale: 1.01;
    }
  }
`;

export const ItemImg = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: ${({ theme }) => theme.palette.background.primary};
  object-fit: contain;
`;

export const ItemTitleWrapper = styled.div`
  ${Txt1};
  width: 100;
  padding: ${({ theme: { spacing } }) => `${spacing(2)} ${spacing(5)}`};
  display: flex;
  gap: ${({ theme }) => theme.spacing()};
`;
