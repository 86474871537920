import { useCallback, useEffect, useState } from 'react';
import { useErrorHandlerContext } from '@omnipkg/ui-kit-web';

import {
  COUNTDOWN,
  COUNTDOWN_DURATION,
  ONE_SECONDS_INTERVAL,
} from '../constants';
import { GetConfirmCodeHookReturns } from '../types';
import { useGetConfirmationCode } from '../utils/getConfirmationCode';

export default function useGetConfirmCodeHandler(): GetConfirmCodeHookReturns {
  const [time, setTime] = useState<number>();
  const [isPending, setIsPending] = useState(false);

  const { handleError } = useErrorHandlerContext();

  const getConfirmationCodeRequest = useGetConfirmationCode();

  const startCountdown = useCallback((duration: number) => {
    setTime(duration);
  }, []);

  const getConfirmationCode = useCallback(
    async (phone: string) => {
      setIsPending(true);
      const success = true;

      return getConfirmationCodeRequest(phone)
        .then(() => {
          startCountdown(COUNTDOWN_DURATION);
          return success;
        })
        .catch(({ message }) => {
          handleError(message);
          return !success;
        })
        .finally(() => setIsPending(false));
    },
    [startCountdown, handleError, getConfirmationCodeRequest],
  );

  useEffect(() => {
    const savedTime = sessionStorage.getItem(COUNTDOWN);
    if (savedTime) {
      startCountdown(Number(savedTime));
    }
  }, [startCountdown]);

  useEffect(() => {
    if (!time) return;

    const interval = setInterval(() => {
      setTime((prevTime) => {
        const nextTime = (prevTime as number) - 1;

        if (nextTime) {
          sessionStorage.setItem(COUNTDOWN, nextTime.toString());
        } else {
          clearInterval(interval);
          sessionStorage.removeItem(COUNTDOWN);
        }
        return nextTime;
      });
    }, ONE_SECONDS_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [time]);

  return {
    time,
    isCodeGetting: isPending,
    getConfirmationCode,
  };
}
