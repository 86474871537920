import { SVGIcon, useTranslations } from '@omnipkg/ui-kit-web';

import SideTab from '@src/components/SideTab';
import { useAppContext } from '@src/contexts/AppContext/AppContext';

import ProhibitedGoodsTab from './components/ProhibitedGoodsTab';
import box from './images/box.png';
import weight from './images/weight.png';

import {
  ButtonTitle,
  ButtonTitleWrapper,
  CustomMainContainer,
  Description,
  SideTabButton,
  Title,
  TitleWrapper,
  VisualContainer,
  VisualDivider,
  VisualUnit,
  VisualUnitTitle,
} from './styled';

export default function ProhibitedGoods(): JSX.Element {
  const { t } = useTranslations();
  const { prohibitedGoodsRef, confirmActions } = useAppContext();

  const maxSize = `${t('max_size')} 64x36x41 ${t('cm')}`;
  const maxWeight = `${t('max_weight')} 30 ${t('kg')}`;

  function openProhibitedGoodsTab() {
    prohibitedGoodsRef.current?.open();
  }

  function closeProhibitedGoodsTab() {
    prohibitedGoodsRef.current?.close();
  }

  function rejectConfirm() {
    confirmActions?.cancel();
  }

  return (
    <>
      <CustomMainContainer>
        <TitleWrapper>
          <SVGIcon name="box" size={20} />
          <Title>{t('package_requirements')}</Title>
        </TitleWrapper>
        <Description>{t('dimensions_and_weight')}</Description>
        <VisualContainer>
          <VisualUnit>
            <img alt="package" width={62} height={44} src={box} />
            <VisualUnitTitle>{maxSize}</VisualUnitTitle>
          </VisualUnit>
          <VisualDivider />
          <VisualUnit>
            <img alt="weight" width={39} height={44} src={weight} />
            <VisualUnitTitle>{maxWeight}</VisualUnitTitle>
          </VisualUnit>
        </VisualContainer>
        <SideTabButton onClick={openProhibitedGoodsTab}>
          <ButtonTitleWrapper>
            <SVGIcon name="prohibition" size={24} />
            <ButtonTitle>{t('prohibited_goods')}</ButtonTitle>
          </ButtonTitleWrapper>
          <SVGIcon name="chevron" size={16} rotate={-90} />
        </SideTabButton>
      </CustomMainContainer>
      <SideTab ref={prohibitedGoodsRef} onClose={rejectConfirm}>
        <ProhibitedGoodsTab closeTab={closeProhibitedGoodsTab} />
      </SideTab>
    </>
  );
}
